import { Elements } from '@stripe/react-stripe-js';
import { Icons } from 'assets/icons/icons.index';
import { Images } from 'assets/images/images.index';
import {
  Button,
  CartCheckout,
  DetailAccordion,
  Divider,
  FormCoupon,
  ItemList,
  PaymentMethodDlocal,
  PaymentMethodStripe,
  PopupDetailAccordion,
  PopupExistProcess,
  ServiceActivationMessage,
  SummaryInformationTotals,
  SummaryInformationTotalsShortLink,
  TitleWhitImage,
} from 'components';
import { config } from 'config/constants';
import Recaptcha from 'react-google-invisible-recaptcha';
import { formatPrice } from 'utils/payments.utils';
import { getArrayForPlanIds } from 'utils/pricing.utils';
import { PeriodicityTypesOptions } from '../../models/Payment-subscription.interface';
import { usePaymentInformation } from './hooks/usePaymentInformation';
import { IProduct } from './models/Payment-information.interface';
import './Payment-information.scss';

export const Paymentinformation = (props: {
  handleStep: (index: number) => void;
  link: string;
}) => {
  const {
    phoneBranch,
    cartCheckoutRef,
    stripeLoad,
    stripeRef,
    dlocalRef,
    setCapcha,
    formCouponRef,
    handleCouponInfo,
    options,
    checkoutReducer,
    branchReducer,
    onCartCheckoutChanged,
    formPaymentSubscription,
    changeValueInputStripe,
    errorPayment,
    addMonthsOrYears,
    popUp,
    showInfoPopUp,
    exitProcess,
  } = usePaymentInformation({ ...props });

  return (
    <Elements stripe={stripeLoad} options={options}>
      <div
        className={`${
          checkoutReducer.short_link
            ? 'container__payment__header--none'
            : 'container__payment__header'
        }`}
      >
        <PopupDetailAccordion title="Resumen orden" icon={Icons.factura}>
          <CartCheckout
            isCuponHidden={true}
            branch={branchReducer.branch}
            planItemsQuantity={
              checkoutReducer.order?.dataProducts?.planItemsQuantity
            }
            allProducts={checkoutReducer.products as IProduct[]}
            orderId={checkoutReducer.order?.id as string}
            coupon={checkoutReducer.order?.dataOrderCompleted?.coupon}
            ref={cartCheckoutRef}
            templete="outline"
            agentId={
              (checkoutReducer.short_link_properties?.agentId as string) ||
              checkoutReducer.agentId
            }
            agentName={checkoutReducer.agentName as string}
            periodicity={checkoutReducer.order?.dataOrderCompleted.periodicity}
            companyName={checkoutReducer.company as string}
            onCartCheckoutChanged={onCartCheckoutChanged}
            termsAndConditions={
              checkoutReducer.order?.dataOrderCompleted?.coupon
                ?.termsAndConditions
                ? checkoutReducer.order.dataOrderCompleted.coupon
                    .termsAndConditions
                : ''
            }
          ></CartCheckout>
          <div style={{ height: '40px' }}></div>
        </PopupDetailAccordion>
      </div>
      <form
        className={`container__payment container__generic ${
          checkoutReducer.short_link ? 'shortLink' : ''
        }`}
        onSubmit={formPaymentSubscription.handleSubmit}
        noValidate
      >
        {errorPayment && (
          <>
            <div className="payment__padding">
              <DetailAccordion tilte="ERROR EN EL PAGO" type="error">
                {errorPayment.response?.data?.error?.message}
                <br />
                Ocurrió un error con tú método de pago, verifica la información
                digitada. Si el error persiste comunícate con nosotros al{' '}
                <b>
                  <a
                    style={{ color: '#fff', textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                    href={`https://wa.me/${phoneBranch}`}
                  >
                    {' '}
                    {phoneBranch}
                  </a>
                </b>
              </DetailAccordion>
            </div>
            <Divider classWrapper="payment__margin" type="bar" />
          </>
        )}
        <div className="content__payment payment__padding">
          {checkoutReducer.short_link && (
            <div className="content__payment__short__link">
              <TitleWhitImage
                title="Detalles de su <br />solicitud"
                image={Images.vanSrConnect}
                marginSmall={true}
                styleInLineImage={{ width: '6.25rem', height: '6.25rem' }}
              ></TitleWhitImage>
              <SummaryInformationTotalsShortLink
                templete="default"
                values={{
                  total: checkoutReducer.resumen?.price as number,
                  subtotal: checkoutReducer.resumen?.subTotal as number,
                  discount: checkoutReducer.resumen?.discount as number,
                  couponAmount: checkoutReducer.resumen
                    ?.discountCoupon as number,
                  discountForFreeTrial:
                    checkoutReducer.order?.dataOrderCompleted
                      ?.discountForFreeTrial || 0,
                  priceForRoad:
                    checkoutReducer.order?.dataOrderCompleted?.priceForRoad ||
                    0,
                  totalsForPlan: checkoutReducer.order?.dataOrderCompleted
                    ?.totalsForPlan || [{ price: 0 }],
                }}
                branch={branchReducer.branch}
                isTrialPeriod={
                  checkoutReducer.order?.dataOrderCompleted
                    ?.discountForFreeTrial
                    ? true
                    : false
                }
                termsAndConditions={
                  checkoutReducer.order?.dataOrderCompleted?.coupon
                    ?.terms_and_conditions
                }
                couponName={
                  checkoutReducer.order?.dataOrderCompleted?.coupon?.name
                }
                periodicity={
                  checkoutReducer.order?.dataOrderCompleted?.periodicity
                }
                products={checkoutReducer.order?.dataOrderCompleted?.products}
              ></SummaryInformationTotalsShortLink>
            </div>
          )}
          <TitleWhitImage
            title="información <br />de pago"
            image={Images.paymentInfo}
            styleInLineImage={{ width: '4.25rem', height: '4.25rem' }}
          ></TitleWhitImage>
          <a
            className="stripe__badge"
            href="https://stripe.com/docs/security"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Images.stripeBadge} alt="Logo pago seguro" />
          </a>
          {branchReducer.branch === 'pr' ||
          (
            checkoutReducer?.products as IProduct[]
          )[0].attributes.company.data.attributes?.formToShow.toLowerCase() ===
            'stripe' ? (
            <PaymentMethodStripe
              id="payment-informarion-number"
              inputNameLabel="Nombre de la tarjeta"
              inputNameControl="card_name"
              handleChangeNameInput={formPaymentSubscription.handleChange}
              handleBlurNameInput={formPaymentSubscription.handleBlur}
              touchedNameInput={formPaymentSubscription.touched.card_name}
              errorNameInput={formPaymentSubscription.errors.card_name}
              valueNameInput={formPaymentSubscription.values.card_name}
              form={formPaymentSubscription}
              handleChangeStripeInput={changeValueInputStripe}
              ref={stripeRef}
            />
          ) : (
            <PaymentMethodDlocal
              inputNameLabel="Nombre en la tarjeta"
              inputNameControl="card_name"
              handleChangeNameInput={formPaymentSubscription.handleChange}
              handleBlurNameInput={formPaymentSubscription.handleBlur}
              touchedNameInput={formPaymentSubscription.touched.card_name}
              errorNameInput={formPaymentSubscription.errors.card_name}
              valueNameInput={formPaymentSubscription.values.card_name}
              form={formPaymentSubscription}
              handleChangeDlocalInput={changeValueInputStripe}
              ref={dlocalRef}
              country={branchReducer.branch as 'co' | 'cr'}
            ></PaymentMethodDlocal>
          )}
          <FormCoupon
            templete="outline"
            ref={formCouponRef}
            orderId={checkoutReducer.order?.id as string}
            value={
              checkoutReducer.order?.dataOrderCompleted?.coupon?.coupon_code ??
              ''
            }
            coupon={checkoutReducer.order?.dataOrderCompleted?.coupon}
            onResetCuponInfo={handleCouponInfo}
            onGetCoupon={handleCouponInfo}
            periodicity={
              checkoutReducer.order?.dataProducts
                .periodicity as PeriodicityTypesOptions
            }
            branch={branchReducer.branch}
            marginSmall={checkoutReducer.trialPeriod ? true : false}
            planIds={getArrayForPlanIds(
              checkoutReducer.order?.dataOrderCompleted.products
            )}
          ></FormCoupon>
          {!checkoutReducer.short_link && (
            <SummaryInformationTotals
              templete="outline"
              values={{
                total: checkoutReducer.resumen?.price as number,
                subtotal: checkoutReducer.resumen?.subTotal as number,
                discount: checkoutReducer.resumen?.discount as number,
                couponAmount: checkoutReducer.resumen?.discountCoupon as number,
                discountForFreeTrial:
                  checkoutReducer.order?.dataOrderCompleted
                    ?.discountForFreeTrial || 0,
                priceForRoad:
                  checkoutReducer.order?.dataOrderCompleted?.priceForRoad || 0,
                totalsForPlan: checkoutReducer.order?.dataOrderCompleted
                  ?.totalsForPlan || [{ price: 0 }],
              }}
              branch={branchReducer.branch}
              decorationHidden={true}
              isTrialPeriod={
                checkoutReducer.order?.dataOrderCompleted?.discountForFreeTrial
                  ? true
                  : false
              }
              termsAndConditions={
                checkoutReducer.order?.dataOrderCompleted?.coupon
                  ?.terms_and_conditions
              }
              couponName={
                checkoutReducer.order?.dataOrderCompleted?.coupon?.name
              }
              periodicity={
                checkoutReducer.order?.dataOrderCompleted?.periodicity
              }
              products={checkoutReducer.order?.dataOrderCompleted?.products}
            ></SummaryInformationTotals>
          )}
          {checkoutReducer.trialPeriod ? (
            <p className="cart__checkout__message__trial">
              Tienes {checkoutReducer.trialPeriod} días para disfrutar
              totalmente gratis del servicio. Finalizado el tiempo se realizará
              el cobro de $
              {formatPrice(
                checkoutReducer.order?.dataOrderCompleted.total,
                branchReducer.branch
              )}
              . Beneficio válido solo para miembros de {checkoutReducer.company}{' '}
              aplican T&C.
            </p>
          ) : (
            ''
          )}
          {checkoutReducer.trialPeriod ? (
            <p className="message__trial">
              Se cobrara al finalizar los {checkoutReducer.trialPeriod} días de
              prueba
            </p>
          ) : (
            ''
          )}
          <div className="container__list">
            <ItemList>
              El siguiente pago se realizará el {addMonthsOrYears()}.
            </ItemList>
            <ServiceActivationMessage />
            <ItemList>
              En el próximo paso podrás ingresar la información de tus
              propiedades.
            </ItemList>
          </div>
          <Button
            className="content__payment__button relative"
            size="medium"
            color="primary"
            template="primary"
            typeStyle="rounded"
            type="button"
            label="realizar pago"
            onClick={formPaymentSubscription.handleSubmit}
            isDisabled={
              formPaymentSubscription.isSubmitting ||
              !formPaymentSubscription.isValid ||
              !formPaymentSubscription.dirty
            }
            isLoading={formPaymentSubscription.isSubmitting}
            testid="btn__buy__plan"
          ></Button>
        </div>
      </form>
      {popUp.showModal && (
        <PopupExistProcess
          title={popUp.title}
          message={popUp.message}
          labelButton={popUp.labelButton}
          labelButtonCancel="Salir"
          type="warning"
          closePopUp={showInfoPopUp}
          exitProcess={exitProcess}
        />
      )}
      <Recaptcha
        ref={(ref: unknown) => setCapcha(ref)}
        sitekey={config.recaptchaKey}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onResolved={() => {}}
      />
    </Elements>
  );
};
