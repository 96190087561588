import {
  ButtonAddItem,
  PaymentsBillingSkeleton,
  PopupDelete,
  PopupExistProcess,
  TitleSection,
} from 'components';
import { Card } from 'pages/Settings/pages/Payments-billing/models/Card.interface';
import { useNavigate } from 'react-router-dom';

interface PaymentMethodProps {
  isLoadingCards: boolean;
  userCards: Card[] | undefined;
  renderUserCards: (
    userCards: Card[] | undefined,
    infoPopUp: any,
    showPopUp: boolean,
    handlePopUp: (id?: number | string) => Promise<void>,
    showPopUpEditCardCredit: (card: Card) => void
  ) => JSX.Element;
  infoPopUp: any;
  showPopUp: boolean;
  handlePopUp: (id?: number | string) => Promise<void>;
  showPopUpEditCardCredit: (card: Card) => void;
  showModalEditPaymentMethod: string;
  setShowModalEditPaymentMethod: (value: React.SetStateAction<string>) => void;
  goToEditCardCredit: () => void;
  isLoadingEdit: {
    loading: boolean;
    error: string | undefined;
  };
  amountToRefund: any;
  deleteItemFromUser: () => Promise<void>;
  isLoading: boolean;
}

export const PaymentMethod = ({
  isLoadingCards,
  userCards,
  renderUserCards,
  infoPopUp,
  showPopUp,
  handlePopUp,
  showPopUpEditCardCredit,
  showModalEditPaymentMethod,
  setShowModalEditPaymentMethod,
  goToEditCardCredit,
  isLoadingEdit,
  amountToRefund,
  deleteItemFromUser,
  isLoading,
}: PaymentMethodProps) => {
  const navigate = useNavigate();

  return (
    <>
      {isLoadingCards ? (
        <div className="payments__content">
          <PaymentsBillingSkeleton />
        </div>
      ) : (
        <div className="payments__container__section">
          <TitleSection title="Métodos de Pago">
            <ButtonAddItem
              testid="btn_payment_billing_new_payment_method"
              label="Agregar"
              onClick={() => navigate('/add-payment-method')}
              styleInLine={{ height: 'max-content' }}
              template="primary"
            />
          </TitleSection>
          {renderUserCards(
            userCards,
            infoPopUp,
            showPopUp,
            handlePopUp,
            showPopUpEditCardCredit
          )}
          {showModalEditPaymentMethod && (
            <PopupExistProcess
              title={'Actualizar método de pago'}
              message={
                'El método de pago predeterminado sera actualizado, se utilizara para pagar tus suscripciones o servicios'
              }
              labelButton={'Actualizar'}
              labelButtonCancel="Cancelar"
              type="warning"
              exitProcess={() => setShowModalEditPaymentMethod('')}
              closePopUp={goToEditCardCredit}
              isLoading={isLoadingEdit.loading}
              reverseCallToAction={true}
            />
          )}
          {showPopUp && infoPopUp && (
            <PopupDelete
              amountRefund={amountToRefund}
              chipsInfo={[
                `${infoPopUp.attributes?.plan_id?.data.attributes.user_products.length}/${infoPopUp?.attributes?.plan_id?.data.attributes.productos.data[0].attributes.max_count} propiedades disponibles`,
              ]}
              itemTitle={
                infoPopUp?.attributes?.plan_id?.data.attributes.alias_title
              }
              title={
                infoPopUp?.attributes?.plan_id
                  ? '¿Deseas eliminar el plan?'
                  : '¿Deseas eliminar este método de pago?'
              }
              closePopUp={() => {
                handlePopUp();
              }}
              onAcceptAction={deleteItemFromUser}
              isLoading={isLoading}
              type={
                infoPopUp?.attributes?.plan_id
                  ? 'deletePlan'
                  : 'deleteCardPayment'
              }
              brand={infoPopUp?.brand}
              lastDigits={infoPopUp?.last4}
              expire={`Expira ${infoPopUp?.exp_month}/${infoPopUp?.exp_year}`}
            ></PopupDelete>
          )}
        </div>
      )}
    </>
  );
};
