import { ProcesadorSvg } from 'assets/icons/procesador';
import {
  ButtonAddItem,
  DetailAccordion,
  HeaderWithAlert,
  PaymentMethod,
  PaymentsBillingSkeleton,
  PopUninformativeCustomService,
  PopUpInformative,
  RenewalSubscription,
  TitleSection,
} from 'components';
import { PopupAddOn } from 'components/molecules/Popup-add-on/Popup-add-on';
import { IPropsServicesHistory } from 'components/organisms/Services-history/Services-history';
import { IUserSubscription } from 'pages/Edit-Property/models/Edit-Property.interface';
import { formatDateWithDigits } from 'utils/date.utils';
import { formatPrice } from 'utils/payments.utils';
import './Payments-billing.scss';
import { usePaymentsBillingRender } from './hooks/Payments-billing-render.hook';
import { usePaymentsAndBilling } from './hooks/Payments-billing.hook';
import { InfoChangePlanAddOns } from './models/Payments.interface';

export const PaymentsAndBilling = () => {
  const {
    branch,
    steps,
    navigate,
    userPlans,
    infoNextPayment,
    formatPriceForCountry,
    user,
    goToAddPlan,
    infoPopUp,
    goToWhatsApp,
    handlePopUPDetailPayment,
    handlePopUp,
    handleCancel,
    addAddOns,
    handleRenewalSubscription,
    isLoadingCards,
    userCards,
    payments,
    amountToRefund,
    deleteItemFromUser,
    isLoading,
    showModalEditPaymentMethod,
    showPopUp,
    setShowModalEditPaymentMethod,
    goToEditCardCredit,
    showPopUpEditCardCredit,
    isLoadingEdit,
    isOpenPopUpInformative,
    setIsOpenPopUpInformative,
    userPlanSelected,
    isLoadingRenewalSubscription,
    setIsLoadingRenewalSubscription,
    connectWebAnchor,
    showPopUAddAddOns,
    closePopUpAddon,
    planAddAddOns,
    changePlanToAddOns,
    setInfoSubscription,
    goToPlanDetail,
    isPlanAddOnUpdate,
    infoAddOns,
    setInfoAddOns,
    coupon,
    setCoupon,
    planSelectedForAddons,
  } = usePaymentsAndBilling();

  const { renderPayments, renderUserCards, renderUserPlans } =
    usePaymentsBillingRender();
  return (
    <>
      <HeaderWithAlert
        steps={steps}
        onClick={() => {
          navigate('/settings');
        }}
      ></HeaderWithAlert>
      <div className="payments__title__container">
        {isPlanAddOnUpdate && (
          <DetailAccordion
            tilte="confirmación"
            type="successful"
            wrapperClassName="payments__title__alert"
            isOpen={true}
          >
            <p className="title">Se agrego un beneficio adicional</p>
            <p className="detail">
              El beneficio se ha añadido a tu plan con éxito! Ahora el monto a
              pagar es de{' '}
              <strong> ${formatPrice(infoAddOns?.newPrice, branch)}</strong> por
              un periodo de 12 meses de uso, hasta la fecha{' '}
              {formatDateWithDigits(
                planSelectedForAddons?.attributes.plan_id.data.attributes
                  .user_products[0].date_expire as string
              )}
              .
            </p>
          </DetailAccordion>
        )}
        <h6 className="payments__title">Planes y pagos</h6>
        {!userPlans?.length && (
          <p>Escoge un plan y ahorra dinero con nuestros servicios</p>
        )}
        {infoNextPayment && (
          <p>
            Próxima factura el {infoNextPayment.month}/{infoNextPayment.day}/
            {infoNextPayment.year}&nbsp;por $
            {formatPriceForCountry(infoNextPayment.amount)}
          </p>
        )}
      </div>
      <div className="payments__container container__generic">
        {(user.email === 'lmape@connect.com.co' ||
          user.agentAuthData?.email === 'lmape@connect.com.co') && (
          <RenewalSubscription></RenewalSubscription>
        )}
        {userPlans === undefined ? (
          <div className="payments__content">
            <PaymentsBillingSkeleton />
          </div>
        ) : (
          <div className="payments__container__section">
            <TitleSection title="PLANES Y BENEFICIOS">
              {user.user_type === 'colaborador' && !user.agentId ? (
                ''
              ) : (
                <ButtonAddItem
                  testid="btn_payment_billing_add_plan"
                  label="Agregar"
                  onClick={goToAddPlan}
                  template="primary"
                />
              )}
            </TitleSection>
            {renderUserPlans(
              userPlans as IUserSubscription[],
              user,
              infoPopUp,
              showPopUp,
              goToWhatsApp,
              handlePopUPDetailPayment,
              handleCancel,
              addAddOns,
              handleRenewalSubscription,
              setInfoSubscription,
              goToPlanDetail,
              branch
            )}
          </div>
        )}
        {
          <PaymentMethod
            isLoadingCards={isLoadingCards}
            userCards={userCards}
            renderUserCards={renderUserCards}
            infoPopUp={infoPopUp}
            showPopUp={showPopUp}
            handlePopUp={handlePopUp}
            showPopUpEditCardCredit={showPopUpEditCardCredit}
            showModalEditPaymentMethod={showModalEditPaymentMethod}
            setShowModalEditPaymentMethod={setShowModalEditPaymentMethod}
            goToEditCardCredit={goToEditCardCredit}
            isLoadingEdit={isLoadingEdit}
            amountToRefund={amountToRefund}
            deleteItemFromUser={deleteItemFromUser}
            isLoading={isLoading}
          />
        }
        {renderPayments(
          payments as IPropsServicesHistory['data'],
          branch,
          user
        )}
      </div>
      <a
        href="/"
        ref={connectWebAnchor}
        className="anchor__link"
        rel="noreferrer"
        target="_blank"
      >
        Procesador
      </a>
      {isOpenPopUpInformative && (
        <PopUninformativeCustomService
          closePopUp={() => setIsOpenPopUpInformative(false)}
        >
          <div className="item_popup_custom_service__container">
            <div className="item_popup_custom_service">
              <span className="title">PROCESADOR: </span>
              <button
                className="icon"
                onClick={() =>
                  navigator.clipboard.writeText(
                    userPlanSelected?.attributes?.payment_provider.data.id === 2
                      ? userPlanSelected?.attributes.customer_stripe_id
                      : (userPlanSelected?.attributes
                          .stripe_subscription_id as string)
                  )
                }
              >
                {ProcesadorSvg({ color: 'secondary' })}
                {`${userPlanSelected?.attributes.provider_processor}`}
              </button>
            </div>
            <div className="item_popup_custom_service">
              <span className="title">PAYMENT_ID: </span>
              <span className="underline">
                {userPlanSelected?.attributes.payments?.data.length
                  ? userPlanSelected?.attributes.payments?.data[0].attributes
                      .provider_reference_id || 'No disponible'
                  : 'No disponible'}
              </span>
            </div>
            <div className="item_popup_custom_service">
              <span className="title">subscription_id: </span>
              <span className="underline">
                {userPlanSelected?.attributes.stripe_subscription_id}
              </span>
            </div>
            <div className="item_popup_custom_service">
              <span className="title">Nota: </span>
              <span>
                Revisa si la compra de este plan fue anterior o posterior al
                lanzamiento de D.Local para facilitar la gestión de
                transacciones de la suscripción.
              </span>
            </div>
          </div>
        </PopUninformativeCustomService>
      )}
      {isLoadingRenewalSubscription.isOpen && (
        <PopUpInformative
          title={isLoadingRenewalSubscription.title}
          type={isLoadingRenewalSubscription.type}
          closePopUp={() => {
            setIsLoadingRenewalSubscription({
              isOpen: false,
              loading: true,
              type: 'warning',
              title: 'Renovando plan',
              message: ' Esto tomara un momento',
            });
          }}
        >
          {isLoadingRenewalSubscription.message}
          {isLoadingRenewalSubscription.loading ? (
            <div className="payments__loader__container">
              <div className="payments__loader__content">
                <div className="payments__loader"></div>
              </div>
            </div>
          ) : (
            ''
          )}
        </PopUpInformative>
      )}
      {showPopUAddAddOns && (
        <PopupAddOn
          branch={branch}
          planAddOn={planAddAddOns}
          addOn={
            planAddAddOns?.length
              ? planAddAddOns[0]?.attributes.productos.data.map(
                  (product) => product.attributes.profit_title
                )
              : ['']
          }
          infoAddOns={infoAddOns as InfoChangePlanAddOns}
          setInfoAddOns={setInfoAddOns}
          coupon={coupon}
          setCoupon={setCoupon}
          process={changePlanToAddOns}
          closePopUp={closePopUpAddon}
        />
      )}
    </>
  );
};
