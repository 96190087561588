import axios from 'axios';
import { config } from 'config/constants';
import { TypeAppErrors, TypeCodeServiceAppErrors } from 'models/app.interface';
import { IAppReducer } from 'models/reducer.interface';
import { store } from 'store/app.store';
import { sendLog } from './logs.service';

const errorCodes = [
  TypeCodeServiceAppErrors.INTERNAL,
  TypeCodeServiceAppErrors.UNAUTHORIZED,
  TypeCodeServiceAppErrors.NOTALLOWED,
];
const cmsInstance = axios.create({
  baseURL: config.merakiCms.url,
  responseType: 'json',
});
cmsInstance.interceptors.request.use((request) => {
  const storeData: IAppReducer = store.getState();
  const accessToken =
    storeData.checkoutReducer.accessToken ||
    storeData.authReducer.accessToken ||
    config.appiKey;
  if (request && request.headers) {
    request.headers['Authorization'] = `Bearer ${accessToken}`;
    return request;
  }
});

cmsInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config } = error;
    const storeData: IAppReducer = store.getState();
    const data = {
      nameError: TypeAppErrors.ERRORSERVICE,
      error: {
        type: 'Error ' + error?.response?.status,
        message: error?.message,
        data: error?.stack || error?.message,
      },
      payload: {
        scope: window.location.pathname,
        action: error.request.responseURL,
        url: window.location.href,
        user:
          storeData.authReducer.email ||
          (storeData.checkoutReducer.email as string),
        funelId: storeData.checkoutReducer.funelId,
        orderId: storeData.checkoutReducer.order?.id as string,
      },
    };
    if (
      !config ||
      !config.retry ||
      !errorCodes.includes(error.response.status)
    ) {
      return Promise.reject(error);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise<void>((resolve) => {
      setTimeout(() => {
        sendLog(data);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => cmsInstance(config));
  }
);

export default cmsInstance;
