import { Elements } from '@stripe/react-stripe-js';
import { Icons } from 'assets/icons/icons.index';
import { Images } from 'assets/images/images.index';
import {
  Button,
  ButtonAddItem,
  ButtonClose,
  CardBody,
  DetailAccordion,
  Divider,
  EditPlanSkeleton,
  Input,
  ItemAddProperty,
  ItemCreditCard,
  PaymentMethodDlocal,
  PaymentMethodStripe,
  PopupExistProcess,
  PopupOverlay,
  Select,
  SummaryInformationPrices,
  TagLabel,
} from 'components';
import { TypeInput } from 'components/atoms/Input/Input';
import { typeProperty as appConstants } from 'constants/app.constants';
import { TypeProperty } from 'models/app.interface';
import { Card } from 'pages/Settings/pages/Payments-billing/models/Card.interface';
import { useState } from 'react';
import { formatPrice } from 'utils/payments.utils';
import { isPromoActive } from 'utils/pricing.utils';
import './Edit-plan-information.scss';
import { useEditPlanInformation } from './hooks/useEditPlanInformation';

export const EditPlanInformation = (props: {
  handleStep: (index: number) => void;
}) => {
  const {
    stripeLoad,
    branch,
    errorPayment,
    phoneBranch,
    authReducer,
    propertiesInfo,
    planInformation,
    periodicity,
    isLoadingScreen,
    planItemsQuantity,
    handlePlanItemsQuantity,
    prices,
    infoProperties,
    setAmountCustom,
    propertiesNumberEdit,
    formEditPlan,
    handleSelectOption,
    propertiesInfoSelect,
    isNewCreditCard,
    newPaymentMethod,
    contentEl,
    changeValueInputStripe,
    stripeRef,
    dlocalRef,
    typeProperty,
    branchReducer,
    userCards,
    isLoading,
    isOpenModalReason,
    editPlan,
    handleCloseModalReason,
    formReason,
    amountCustom,
    processIsIncomplete,
    handleInfoIncompleted,
    handleClosePopUpProcessIncompleted,
    addNewProperty,
    setAddNewProperty,
    formAddProperty,
    handleSelectCard,
    getPlanType,
    isPlanMigratedPriceSpecial,
  } = useEditPlanInformation({
    ...props,
  });

  const planType = getPlanType();
  const [isAmountCustomInvalid, setIsAmountCustomInvalid] =
    useState<boolean>(false);
  const handleInvalidAmountCustom = (isInvalid: boolean) => {
    setIsAmountCustomInvalid(isInvalid);
  };

  const getType = (
    planItemsQuantity: number,
    infoProperties: { quantity: number } | undefined
  ) => {
    return planItemsQuantity - (infoProperties?.quantity || 0) === 0
      ? 'no edit'
      : planItemsQuantity - (infoProperties?.quantity || 0) > 0
      ? 'increase'
      : 'decrease';
  };

  return (
    <>
      <Elements stripe={stripeLoad}>
        <div className="edit__plan__information__container container__generic">
          <div className="edit__plan__information__title__container">
            {errorPayment && (
              <>
                <DetailAccordion tilte="ERROR EN EL PAGO" type="error">
                  Ocurrió un error con tú método de pago, verifica la
                  información digitada. Si el error persiste comunícate con
                  nosotros al{' '}
                  <b>
                    <a
                      style={{ color: '#fff', textDecoration: 'none' }}
                      target="_blank"
                      rel="noreferrer"
                      href={`https://wa.me/${phoneBranch}`}
                    >
                      {phoneBranch}
                    </a>
                  </b>
                  &nbsp;
                  <br />
                  {authReducer.agentId
                    ? errorPayment.response?.data?.error?.message !==
                      'Not Found'
                      ? errorPayment.response?.data?.error?.message
                      : ''
                    : ''}
                </DetailAccordion>
                <Divider classWrapper="" type="bar" />
              </>
            )}
            <h1>Editar {planType}</h1>
            {propertiesInfo.length ? (
              <>
                <div className="title__product">
                  {
                    planInformation?.attributes.plan_id.data.attributes
                      .alias_name
                  }{' '}
                  |{' '}
                  <strong>
                    {
                      planInformation?.attributes.plan_id.data.attributes
                        .alias_product_type
                    }
                  </strong>
                </div>
                <span>
                  Suscripción{' '}
                  {periodicity && (periodicity as string).toLowerCase()}
                </span>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="edit__plan__information__count__container">
            {isLoadingScreen ? (
              <EditPlanSkeleton></EditPlanSkeleton>
            ) : (
              <>
                <CardBody>
                  <h3 className="edit__plan__information__count__title">
                    Cantidad
                  </h3>
                  <p className="edit__plan__information__count__info">
                    Agrega o quita propiedades de tu plan.
                  </p>
                  {planInformation && typeProperty && (
                    <ItemAddProperty
                      id="edit"
                      template="secondary"
                      maxItems={10}
                      isDisabledButtonPlus={planItemsQuantity >= 10}
                      isDisabledButtonLess={planItemsQuantity <= 1}
                      isDisabled={false}
                      type={{ ...appConstants }[typeProperty] as TypeProperty}
                      onClick={handlePlanItemsQuantity}
                      count={planItemsQuantity}
                      branch={branch}
                      priceForUnit={prices.priceByUnit}
                    />
                  )}
                  <div className="edit__plan__information__divider__container"></div>
                  {planInformation?.attributes.plan_id.data.attributes
                    .user_products[0].coupon && (
                    <div className="coupon__info__container">
                      <TagLabel
                        type="secondary"
                        typeStyle="square"
                        onClick={undefined}
                      >
                        <img src={Icons.iconTag} alt="icono tag" />
                        <div className="tag__label__info__coupon">
                          <span className="tag__label__info__coupon--bold">
                            {planInformation?.attributes.plan_id.data.attributes.user_products[0].coupon.coupon_code?.toLocaleUpperCase()}
                          </span>
                          {!isPromoActive && (
                            <span>
                              {planInformation?.attributes.plan_id.data
                                .attributes.user_products[0].coupon.is_percent
                                ? `${planInformation?.attributes.plan_id.data.attributes.user_products[0].coupon.amount}% dcto.`
                                : `-$${formatPrice(
                                    planInformation?.attributes.plan_id.data
                                      .attributes.user_products[0].coupon
                                      .amount,
                                    branch
                                  )}`}{' '}
                            </span>
                          )}
                        </div>
                      </TagLabel>
                    </div>
                  )}
                  <SummaryInformationPrices
                    onInvalidAmountCustom={handleInvalidAmountCustom}
                    isPlanMigratedPriceSpecial={isPlanMigratedPriceSpecial()}
                    typeProperty={
                      planInformation
                        ? planInformation.attributes.plan_id.data.attributes.alias_product_type.toLocaleLowerCase()
                        : ''
                    }
                    type={getType(planItemsQuantity, infoProperties)}
                    valuesIsHidden={
                      planItemsQuantity !== infoProperties?.quantity
                    }
                    branch={branch}
                    values={{ ...prices }}
                    updateValue={setAmountCustom}
                  ></SummaryInformationPrices>
                </CardBody>

                {planItemsQuantity !== infoProperties?.quantity && (
                  <CardBody overflowIsVisible={true}>
                    <h3 className="edit__plan__information__count__title">
                      Propiedades
                    </h3>
                    <p className="edit__plan__information__count__info">
                      <strong>
                        Seleccionar {propertiesNumberEdit.length}{' '}
                        {planInformation?.attributes.plan_id.data.attributes.alias_product_type.toLowerCase()}{' '}
                        a{' '}
                        {getType(planItemsQuantity, infoProperties) ===
                        'increase'
                          ? 'cubrir'
                          : 'eliminar'}
                        :
                      </strong>
                      <br />
                      Puedes regresar a completar esta información cuando desees
                      en la sección mis propiedades
                    </p>
                    <div className="edit__plan__information__properties__container">
                      {propertiesNumberEdit.map((itemVehicle, index) => (
                        <Select
                          indexSelect={index}
                          key={`select-${index}`}
                          id={`property_${index}`}
                          placeholder={
                            formEditPlan.values[`property_${index}`]
                              ? `Auto ${index + 1}`
                              : `Selecciona auto ${index + 1}`
                          }
                          value={formEditPlan.values[`property_${index}`]}
                          name={`property_${index}`}
                          onClick={handleSelectOption}
                          isRequired={true}
                          options={propertiesInfoSelect}
                          WrapperClassName="grid__span-6"
                          onBlur={formEditPlan.handleBlur}
                          errorSelect={
                            formEditPlan.errors[`property_${index}`] as string
                          }
                          isTouched={
                            formEditPlan.touched[`property_${index}`] as boolean
                          }
                        />
                      ))}
                    </div>
                  </CardBody>
                )}
                {planItemsQuantity > (infoProperties?.quantity as number) && (
                  <>
                    <CardBody>
                      <h1 className="edit__plan__information__payment__title">
                        Pago
                      </h1>
                      <p className="edit__plan__information__count__info">
                        <strong>Método de pago utilizado actualmente.</strong>
                        <br />
                        Si deseas puedes seleccionar otro método de pago, el
                        cual quedará como el método principal.
                      </p>
                      <ButtonAddItem
                        testid="btn_edit_plan_info_new_payment_method"
                        label={
                          isNewCreditCard ? 'Volver' : 'nuevo método de pago'
                        }
                        onClick={newPaymentMethod}
                        styleInLine={{ margin: '0 0 1.031rem auto' }}
                        template="primary"
                      />
                      {isNewCreditCard ? (
                        <>
                          <div
                            ref={contentEl}
                            style={{ marginTop: '1.875rem' }}
                          ></div>

                          {branch === 'pr' ||
                          planInformation?.attributes?.plan_id?.data?.attributes
                            ?.payment_provider?.id === 1 ||
                          !planInformation?.attributes?.plan_id?.data
                            ?.attributes?.payment_provider?.id ? (
                            <PaymentMethodStripe
                              id="payment-informarion-number"
                              inputNameLabel="Nombre de la tarjeta"
                              inputNameControl="card_name"
                              handleChangeNameInput={formEditPlan.handleChange}
                              handleBlurNameInput={formEditPlan.handleBlur}
                              touchedNameInput={
                                formEditPlan.touched.card_name as boolean
                              }
                              errorNameInput={
                                formEditPlan.errors.card_name as string
                              }
                              valueNameInput={formEditPlan.values.card_name}
                              form={formEditPlan}
                              handleChangeStripeInput={changeValueInputStripe}
                              ref={stripeRef}
                            />
                          ) : (
                            <PaymentMethodDlocal
                              inputNameLabel="Nombre en la tarjeta"
                              inputNameControl="card_name"
                              handleChangeNameInput={formEditPlan.handleChange}
                              handleBlurNameInput={formEditPlan.handleBlur}
                              touchedNameInput={
                                formEditPlan.touched.card_name as boolean
                              }
                              errorNameInput={
                                formEditPlan.errors.card_name as string
                              }
                              valueNameInput={formEditPlan.values.card_name}
                              form={formEditPlan}
                              handleChangeDlocalInput={changeValueInputStripe}
                              ref={dlocalRef}
                              country={branchReducer.branch as 'co' | 'cr'}
                            ></PaymentMethodDlocal>
                          )}
                        </>
                      ) : (
                        userCards &&
                        userCards.map((card: Card, index: number) => (
                          <ItemCreditCard
                            key={`item__credit__card__${index}`}
                            id={card.id}
                            onClickSelect={handleSelectCard}
                            lastDigits={card.last4}
                            brand={card.brand}
                            isPrincipal={card.is_principal}
                            isSelected={formEditPlan.values.id_card === card.id}
                            expMonth={card.exp_month}
                            expYear={card.exp_year}
                          />
                        ))
                      )}
                    </CardBody>
                    <div className="service__info">
                      <img className="" src={Images.chipWarning} alt="" />
                      Estás a 72 horas de manejar más tranquilo y siempre
                      acompañado.
                    </div>
                  </>
                )}

                {planItemsQuantity !== infoProperties?.quantity && (
                  <Button
                    className="edit__plan__information__button"
                    color="primary"
                    size="medium"
                    template="primary"
                    typeStyle="rounded"
                    type="button"
                    label="Guardar Cambios"
                    isDisabled={!formEditPlan.isValid || isAmountCustomInvalid}
                    onClick={formEditPlan.handleSubmit}
                    isLoading={isLoading}
                  ></Button>
                )}
              </>
            )}
          </div>
        </div>
      </Elements>

      {isOpenModalReason && (
        <PopupExistProcess
          title="¡Importante!"
          message=""
          labelButton="Realizar pago"
          labelButtonCancel="Salir"
          type="warning"
          reverseCallToAction={true}
          closePopUp={() => editPlan()}
          exitProcess={handleCloseModalReason}
          isLoading={isLoading}
          isDisabled={!formReason.values.reason}
        >
          {!isPlanMigratedPriceSpecial() && (
            <p>
              Tu plan {periodicity?.toLocaleLowerCase()} pasará de
              <strong>
                {` $ ${formatPrice(prices.current, branch)}`} a{' '}
                {` $ ${formatPrice(prices.newPrice, branch)}`}
              </strong>{' '}
              . Para que el cambio sea efectivo
              {` ${
                planItemsQuantity -
                  (infoProperties ? infoProperties.quantity : 0) >
                0
                  ? 'debe pagar'
                  : 'se debe hacer devolución de'
              }`}{' '}
              <strong>
                una diferencia prorrateada de $ $
                {amountCustom
                  ? amountCustom
                  : formatPrice(prices.refund, branch)}
              </strong>
            </p>
          )}
          <Input
            label="Escribe el motivo de edición"
            id="new-reason"
            type="text"
            name="reason"
            onChange={formReason.handleChange}
            onBlur={formReason.handleBlur}
            value={formReason.values.reason}
            isTouched={formReason.touched.reason}
            errorInput={formReason.errors.reason}
            errorLabel={formReason.errors.reason}
            isRequired={true}
            form={formReason}
          />
        </PopupExistProcess>
      )}
      {processIsIncomplete && (
        <PopupExistProcess
          iconIsHidden={true}
          title="¡Ten presente!"
          message="<strong>Que estás por salir sin haber seleccionado las propiedades a cubrir</strong>, puedes continuar con la actualización, pero recuerda que debes regresar a la sección mis propiedades para completarlo."
          labelButton="Continuar"
          labelButtonCancel="Salir"
          type="warning"
          reverseCallToAction={true}
          closePopUp={handleInfoIncompleted}
          exitProcess={handleClosePopUpProcessIncompleted}
        />
      )}
      {addNewProperty && (
        <PopupOverlay>
          <div className="edit__plan__information__popup__add">
            <ButtonClose
              template="dark"
              size="medium"
              className="button__close"
              onClick={() => setAddNewProperty(false)}
            />
            <h5>
              {
                planInformation?.attributes.plan_id.data.attributes
                  .alias_product_type
              }{' '}
              Nuevo
            </h5>
            <p className="subtitle">
              Ingresa la información del{' '}
              {planInformation?.attributes.plan_id.data.attributes.alias_product_type.toLocaleLowerCase()}{' '}
              que desea cubrir:
            </p>
            <div className="grid rg-0 grid__col-6">
              {planInformation?.attributes.plan_id.data.attributes.productos.data[0].attributes.properties.data.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (atribute, index: number) => (
                  <Input
                    key={`input__atribute-${index}`}
                    WrapperClassName={
                      atribute.attributes.large
                        ? 'grid__span-6'
                        : 'grid__span-3'
                    }
                    label={atribute.attributes.name}
                    id={`input__atribute__${atribute.attributes.name}__${atribute.id}`}
                    type={`${atribute.attributes.type as TypeInput}`}
                    name={`${atribute.attributes.name}__${atribute.id}`}
                    onChange={formAddProperty.handleChange}
                    onBlur={formAddProperty.handleBlur}
                    value={
                      formAddProperty.values[
                        `${atribute.attributes.name}__${atribute.id}`
                      ] || ''
                    }
                    isTouched={
                      formAddProperty.touched[
                        `${atribute.attributes.name}__${atribute.id}`
                      ] as boolean
                    }
                    errorInput={
                      formAddProperty.errors[
                        `${atribute.attributes.name}__${atribute.id}`
                      ] as string
                    }
                    errorLabel={
                      formAddProperty.errors[
                        `${atribute.attributes.name}__${atribute.id}`
                      ] as string
                    }
                    isRequired={atribute.attributes.required as boolean}
                    form={formAddProperty}
                    maxLength={atribute.attributes.length as number}
                  />
                )
              )}
            </div>
            <Button
              color="primary"
              size="medium"
              template="primary"
              typeStyle="rounded"
              className="button__save"
              type="button"
              isDisabled={
                formAddProperty.isSubmitting ||
                !formAddProperty.isValid ||
                !formAddProperty.dirty
              }
              isLoading={formAddProperty.isSubmitting}
              onClick={formAddProperty.handleSubmit}
            >
              Guardar
            </Button>
          </div>
        </PopupOverlay>
      )}
    </>
  );
};
