import { IOptionSelect } from 'components/atoms/Select/Select';
import { ICouponInformation } from 'components/organisms/Form-cupon/models/Form-cupon.interface';
import { useFormik } from 'formik';
import * as Analytics from 'helpers/segment.helper';
import * as zipCode from 'helpers/zipCodes';
import { IAppReducer } from 'models/reducer.interface';
import { updateOrderId } from 'pages/Checkout/services/checkout.service';
import { setInformationForUserRuedaz } from 'pages/Checkout/services/zapier.service';
import { setCheckoutInformation } from 'pages/Checkout/store/actions/checkout.types';
import { TypeProperties } from 'pages/Settings/models/Properties.interface';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getYear } from 'shared/constants/year.constants';
import { createLead, updateLead } from 'shared/services/lead.service';
import { setStateError } from 'store/actions/app.types';
import { setBranch } from 'store/actions/branch.types';
import * as utils from 'utils/branch.utils';
import { v4 as uuidv4 } from 'uuid';
import * as personalInformationConstants from '../constants/Contact-information.constants';
import {
  CountryCode,
  IFormContactInformation,
  IFormErrorsContactInformation,
} from '../models/Contact-information.interface';

export const useContactInformation = (props: {
  handleStep: (index: number) => void;
}) => {
  const passwordGeneric = uuidv4();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    checkoutReducer,
    branchReducer: { branch },
  } = useSelector((state: IAppReducer) => state, shallowEqual);
  const [countryAdministrativeDivision, setCountryAdministrativeDivision] =
    useState<{
      provinces: IOptionSelect[];
      districts: IOptionSelect[];
      cantons: IOptionSelect[];
    }>({ provinces: [], districts: [], cantons: [] });
  const [isErrorPostalCode, setErrorPostalCode] = useState(false);
  const countryId = utils.getCountry(branch).toString();
  const year = getYear(branch);
  const cartCheckoutRef =
    useRef<{
      resetCopunInfo: () => void;
      getCoupon: (coupon: ICouponInformation) => void;
    }>(null);
  useEffect(() => {
    dispatch(
      setCheckoutInformation({
        indicator: utils.getPhoneIndicator(branch),
        country_id: countryId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    (async () => {
      const dataCheckoutStepVviewed = {
        step: 2,
        order_id: checkoutReducer.order?.id as string,
      };
      Analytics.checkoutStepVviewed(dataCheckoutStepVviewed);
    })();
  }, []);

  useLayoutEffect(() => {
    props.handleStep(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (!checkoutReducer.funelId && branch) {
        const getFunnelId = await createLead(branch);
        dispatch(
          setCheckoutInformation({
            funelId: getFunnelId,
          })
        );
      }
    })();
  }, [branch]);

  const getOptionsProvinces = async (country = branch) => {
    try {
      let provincesResponse = [];
      if (country === 'cr') {
        provincesResponse = await zipCode.getProvincias(CountryCode.cr);
      } else if (country === 'co') {
        provincesResponse = await zipCode.getDeparments();
      } else if (country === 'pty') {
        provincesResponse = await zipCode.getProvincias(CountryCode.pty);
      }
      const options: IOptionSelect[] = mappingOptions(provincesResponse);
      setCountryAdministrativeDivision({
        provinces: [...options],
        districts: [],
        cantons: [],
      });
      return options;
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    (async () => getOptionsProvinces(branch))();
  }, [branch]);

  const mappingOptions = (
    array: { id: number; name: string }[]
  ): IOptionSelect[] => {
    return array.map((item: { id: number; name: string }) => {
      return {
        value: item.id.toString(),
        description: item.name,
      };
    }, []);
  };

  const formPersonalInformationInitialValues: IFormContactInformation = {
    name: checkoutReducer ? (checkoutReducer.name as string) : '',
    lastname: checkoutReducer ? (checkoutReducer.lastname as string) : '',
    email: checkoutReducer ? (checkoutReducer.email as string) : '',
    indicator: utils.getPhoneIndicator(branch),
    phone: checkoutReducer ? (checkoutReducer.phone as string) : '',
    country_id: utils.getCountry(branch).toString(),
    acceptTerms: false,
    acceptTermsYearCar: false,
    password: passwordGeneric,
    province: '',
    district: '',
    canton: '',
    documentNumber: checkoutReducer.documentNumber as string,
    zipCode: '',
  };

  const formPersonalInformationValidate = (values: IFormContactInformation) => {
    const errors: IFormErrorsContactInformation = {};
    const phoneLength = utils.lengthPhone(branch);
    if (!values.name) {
      errors.name = 'Este campo es requerido';
    }
    if (!values.lastname) {
      errors.lastname = 'Este campo es requerido';
    }

    if (!values.email) {
      errors.email = 'Este campo es requerido';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Formato de correo electrónico incorrecto';
    }

    if (!values.country_id) {
      errors.country_id = 'Este campo es requerido';
    }
    if (branch === 'pr') {
      if (!values.zipCode) {
        errors.zipCode = 'Este campo es requerido';
      } else if (isErrorPostalCode) {
        errors.zipCode = 'Código incorrecto';
      }
    }
    if (branch !== 'pr') {
      if (!values.province) {
        errors.province = 'Este campo es requerido';
      }
    }

    if (branch === 'cr' || branch === 'pty') {
      if (!values.canton) {
        errors.canton = 'Este campo es requerido';
      }
    }
    if (['cr', 'co'].includes(branch)) {
      if (!values.documentNumber) {
        errors.documentNumber = 'Este campo es requerido';
      }
    }
    if (!values.district) {
      errors.district = 'Este campo es requerido';
    }

    if (!values.acceptTerms) {
      errors.acceptTerms = 'Este campo es requerido';
    }
    if (
      (checkoutReducer.resumen?.category as TypeProperties[]).some((elemento) =>
        [
          TypeProperties.AUTO,
          TypeProperties.VEHICULOS,
          TypeProperties.VEHICULOS_WITHOUT_ACCENT,
          TypeProperties.MOTORA,
          TypeProperties.MOTOCICLETA,
        ].includes(elemento)
      )
    ) {
      if (!values.acceptTermsYearCar) {
        errors.acceptTermsYearCar = 'Este campo es requerido';
      }
    }
    if (!values.indicator) {
      errors.indicator = 'Este campo es requerido';
    }
    if (!values.phone) {
      errors.phone = 'Este campo es requerido';
    } else if (values.phone.length < phoneLength.min) {
      errors.phone = 'Teléfono muy corto';
    } else if (values.phone.length > phoneLength.max) {
      errors.phone = 'Teléfono muy largo';
    }
    return errors;
  };

  const formPersonalInformation = useFormik({
    initialValues: formPersonalInformationInitialValues,
    validate: formPersonalInformationValidate,
    onSubmit: async (values) => {
      try {
        const districtName = countryAdministrativeDivision.districts.find(
          (p) => p.value === values.district
        )?.description as string;
        const provinceName = countryAdministrativeDivision.provinces.find(
          (p) => p.value === values.province
        )?.description as string;
        const cantonName = countryAdministrativeDivision.cantons.find(
          (p) => p.value === values.canton
        )?.description as string;
        dispatch(
          setCheckoutInformation({
            district: districtName,
            province: provinceName,
            canton: cantonName,
          })
        );
        const dataCheckoutStepCompleted = {
          step: 2,
        };
        Analytics.checkoutStepCompleted(dataCheckoutStepCompleted);
        await updateOrderId({
          orderId: checkoutReducer.order?.id as string,
          email: values.email.toLocaleLowerCase(),
          phone: values.phone,
          country: Number(values.country_id),
          plans: checkoutReducer.plan_id as string,
          raw_data: '',
        });
        const dataIdentifyUser = {
          name: values.name,
          lastName: values.lastname,
          email: values.email.toLocaleLowerCase(),
          phone: values.phone,
          plan: checkoutReducer.plan_id,
          address: {
            country: personalInformationConstants.countryOptions.find(
              (p) => p.value == Number(values.country_id)
            )?.description as string,
            postalCode: values.zipCode,
            city: countryAdministrativeDivision.districts.find(
              (p) => p.value == values.district
            )?.description as string,
            state: countryAdministrativeDivision.provinces.find(
              (p) => p.value == values.province
            )?.description as string,
          },
        };
        Analytics.identifyUser(dataIdentifyUser);
        if (checkoutReducer.trialPeriod) {
          setInformationForUserRuedaz({
            email: values.email.toLocaleLowerCase(),
            documentNumber: values.documentNumber,
            name: values.name,
            phone: values.phone,
            documentNumberValidated:
              checkoutReducer.companyAdditionalInformation?.documentNumber,
            plateValidated: checkoutReducer.companyAdditionalInformation?.plate,
            company: checkoutReducer.company?.toLocaleLowerCase(),
          });
        }
        navigate('/checkout/payment-information');
      } catch (error) {
        dispatch(setStateError(true));
      } finally {
        formPersonalInformation.setSubmitting(false);
      }
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFocus = (event: React.FocusEvent<any>) => {
    const { name, value } = event.target;
    if (value) {
      dispatch(setCheckoutInformation({ [name]: value }));
    }
    formPersonalInformation.handleBlur(event);
  };

  const handleChangeCheckTerms = (event: boolean | undefined) => {
    formPersonalInformation.setFieldValue('acceptTerms', !event);
    dispatch(setCheckoutInformation({ acceptTerms: !event }));
  };
  const handleChangeAcceptTermsYearCar = (event: boolean | undefined) => {
    formPersonalInformation.setFieldValue('acceptTermsYearCar', !event);
  };
  const handleOptionCountry = (event: number | string) => {
    const countryValue = { ...personalInformationConstants.countryValue }[
      event
    ] as string;
    if (countryValue !== branch) {
      const indicator = utils.getPhoneIndicator(countryValue);
      const updateData = {
        _id: checkoutReducer.funelId,
        branch: countryValue,
      };
      updateLead({ updateData });
      dispatch(setBranch(countryValue));
      const values = {
        ...formPersonalInformation.values,
        country_id: event as string,
        province: '',
        canton: '',
        district: '',
        zipCode: '',
        indicator,
        id: '',
      };
      formPersonalInformation.setValues(values);
      dispatch(
        setCheckoutInformation({
          indicator: indicator,
          country_id: event as string,
        })
      );
    }
  };
  const handleOptionProvince = async (
    event: number | string,
    country = branch
  ) => {
    let options: IOptionSelect[] = [
      {
        description: '',
        value: '',
      },
    ];
    if (['cr', 'pty'].includes(country)) {
      options = await handleOptionProvinceCostaRica(event);
    } else if (country === 'co') {
      options = await handleOptionMunicipalitiesColombia(event);
    }
    return options;
  };
  const handleOptionProvinceCostaRica = async (event: number | string) => {
    const values = {
      ...formPersonalInformation.values,
      province: event as string,
      canton: '',
      district: '',
      zipCode: '',
    };
    formPersonalInformation.setValues(values);
    const cantonsResponse = await zipCode.getCantons('CRC', event as string);
    const options: IOptionSelect[] = mappingOptions(cantonsResponse);
    setCountryAdministrativeDivision({
      ...countryAdministrativeDivision,
      cantons: [...options],
      districts: [],
    });
    return options;
  };
  const handleOptionMunicipalitiesColombia = async (event: number | string) => {
    const values = {
      ...formPersonalInformation.values,
      province: event as string,
      canton: '',
      district: '',
      zipCode: '',
    };
    formPersonalInformation.setValues(values);
    const responseMunicipalities = await zipCode.getMunicipalities(
      event as string
    );
    const options: IOptionSelect[] = mappingOptions(responseMunicipalities);
    setCountryAdministrativeDivision({
      ...countryAdministrativeDivision,
      cantons: [],
      districts: [...options],
    });
    return options;
  };
  const handleOptionCanton = async (
    event: number | string,
    country = branch
  ) => {
    const values = {
      ...formPersonalInformation.values,
      canton: event as string,
      district: '',
      zipCode: '',
    };
    formPersonalInformation.setValues(values);
    const districtsResponse = await zipCode.getDistricts(
      country === 'cr' ? CountryCode.cr : CountryCode.pty,
      formPersonalInformation.values.province,
      event as string
    );
    const options: IOptionSelect[] = mappingOptions(districtsResponse);
    setCountryAdministrativeDivision({
      ...countryAdministrativeDivision,
      districts: [...options],
    });
    return options;
  };

  const handleIndicatorCountry = (event: number | string) => {
    formPersonalInformation.setFieldValue('indicator', event);
  };
  const handleOptionDistrict = (event: number | string) => {
    formPersonalInformation.setFieldValue('district', event);
    handleOptionZipCode(event);
  };
  const handleOptionZipCode = (event: number | string) => {
    formPersonalInformation.setFieldValue('zipCode', event);
    dispatch(setCheckoutInformation({ zipCode: event }));
  };

  const updateInfoLead = (inputName: string, value: string) => {
    const key = {
      name: 'firstName',
      lastname: 'lastName',
      phone: 'phone',
      email: 'email',
    }[inputName] as string;
    const updateData = {
      _id: checkoutReducer.funelId,
      [key]: value,
      branch: branch,
    };
    updateLead(updateData);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getZipCodeInfo = async (event: any) => {
    const values = {
      ...formPersonalInformation.values,
      province: '',
      canton: '',
      district: '',
    };
    formPersonalInformation.setValues(values);
    setErrorPostalCode(false);
    try {
      formPersonalInformation.handleChange(event);
      const { value } = event.target;
      if (value.length >= 5) {
        const response = await zipCode.getZipCodeInfoPuertoRico(value);
        setCountryAdministrativeDivision({
          ...countryAdministrativeDivision,
          districts: [
            {
              value,
              description: response.places[0]['place name'],
            },
          ],
        });
        handleOptionDistrict(value);
      } else {
        setErrorPostalCode(true);
        formPersonalInformation.setFieldError('zipCode', 'Código incorrecto');
      }
    } catch (error) {
      console.log('error', error);

      setErrorPostalCode(true);
      formPersonalInformation.setFieldError('zipCode', 'Código incorrecto');
      setCountryAdministrativeDivision({
        ...countryAdministrativeDivision,
        districts: [],
        cantons: [],
      });
    }
  };

  const handleComplemetInfo = () => {
    if (
      formPersonalInformation.values.email &&
      !formPersonalInformation.errors.email
    ) {
      const dataIdentifyUser = {
        email: formPersonalInformation.values.email,
        plan: checkoutReducer.plan_id,
      };
      Analytics.identifyUser(dataIdentifyUser);
      const dataCheckoutStepCompleted = {
        step: 2,
      };
      Analytics.checkoutStepCompleted(dataCheckoutStepCompleted);
    }
  };

  return {
    branch,
    checkoutReducer,
    formPersonalInformation,
    cartCheckoutRef,
    handleFocus,
    handleComplemetInfo,
    updateInfoLead,
    handleOptionCountry,
    getZipCodeInfo,
    handleOptionDistrict,
    handleOptionCanton,
    handleOptionProvince,
    handleIndicatorCountry,
    countryAdministrativeDivision,
    handleChangeAcceptTermsYearCar,
    handleChangeCheckTerms,
    navigate,
    year,
  };
};
