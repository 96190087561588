import { Button } from 'components/atoms/Button/Button';
import { DetailAccordion } from 'components/atoms/Detail-accordion/Detail-accordion';
import { ItemList } from 'components/atoms/Item-list/Item-list';
import { PopupExistProcess } from 'components/molecules/Popup-exist-porcess/Popup-exist-porcess';
import { FormikValues } from 'formik';
import { IAppReducer } from 'models/reducer.interface';
import { TypeProperties } from 'pages/Settings/models/Properties.interface';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getPropertyForPlan } from 'shared/services/plans';
import {
  createProductProperties,
  updateProductProperties,
} from 'shared/services/products';
import { IEditPropertyRequest } from '../../../Edit-Property/models/Edit-Property.interface';
import { FormHomes } from './components/Form-homes/Form-homes';
import { FormPets } from './components/Form-pets/Form-pets';
import { FormVehicles } from './components/Form-vehicles/Form-vehicles';
import './Create-properties.scss';
import {
  IAtribute,
  IPropertiesStatus,
  PropertiesResponse,
} from './models/Create-properties.interface';

export interface IPropsCreateProperties {
  handleStep?: (index: number) => void;
  link: string;
  redirect: string;
}

export const CreateProperties = (props: IPropsCreateProperties) => {
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const handleFormValidityChange = (isValid: boolean) => {
    setIsFormValid(isValid);
  };
  const { authReducer, createAccountReducer, checkoutReducer, branchReducer } =
    useSelector((state: IAppReducer) => state);
  const userImpersonationId = authReducer?.agentAuthData?.userId ?? null;
  const navigate = useNavigate();
  const [properties, setProperties] = useState<PropertiesResponse[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [propertiesCreated, setPropertiesCreated] = useState<any[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [initialValuesProperties, setInitialValuesProperties] = useState<{
    [key: string]: string;
  }>({});
  const [formValues, setFormValues] = useState<{ [key: string]: string }>({});
  const filesFormData = new FormData();
  const [propertiesStatus, setPropertiesStatus] = useState<IPropertiesStatus[]>(
    []
  );
  const formPetsRef =
    useRef<{
      getValues: () => FormikValues;
    }>(null);
  const formAutoRef =
    useRef<{
      getValues: () => FormikValues;
    }>(null);
  const formMotorcycleRef =
    useRef<{
      getValues: () => FormikValues;
    }>(null);
  const formHomeRef =
    useRef<{
      getValues: () => FormikValues;
    }>(null);

  const [referenceId, setReferenceId] = useState<string>('');
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [, setPropertiesShortLink] = useState<any>(null);

  const showInfoPopUp = () => {
    setShowPopUp(false);
  };
  const exitProcess = async () => {
    showInfoPopUp();
    await createProperty(formValues, Object.keys(filesFormData).length > 0);
    navigate(props.redirect);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getPropertyForPlan(
          authReducer.uid ||
            checkoutReducer.auth_id ||
            createAccountReducer.auth_id,
          checkoutReducer.user_plans_added?.toString() ||
            createAccountReducer.user_plans_added.toString()
        );

        const dataMapping: PropertiesResponse[] = response.map(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (userProduct: any) => {
            return userProduct.attributes.plan_id.data.attributes.productos.data.map(
              (products: { attributes: PropertiesResponse }) => {
                return {
                  ...products.attributes,
                  user_product:
                    userProduct.attributes.plan_id.data.attributes
                      .user_products[0].id,
                  quantity: userProduct.attributes.plan_id.data.attributes
                    .productos.data[0].attributes.properties_added
                    ? userProduct.attributes.plan_id.data.attributes
                        .user_products[0].quantity -
                        userProduct.attributes.plan_id.data.attributes.productos
                          .data[0].attributes.properties_added <
                      0
                      ? 0
                      : userProduct.attributes.plan_id.data.attributes
                          .user_products[0].quantity -
                        userProduct.attributes.plan_id.data.attributes.productos
                          .data[0].attributes.properties_added
                    : userProduct.attributes.plan_id.data.attributes
                        .user_products[0].quantity,
                };
              }
            )[0];
          }
        );

        dataMapping.forEach((typeProperty, indexProperty: number) => {
          typeProperty.product_type_id.data.stepActive = 0;
          propertiesStatus[indexProperty] = {
            id: typeProperty.product_type_id.data.id,
            atributes: typeProperty.properties.data.map((element) => {
              return {
                id: element.id,
                name: element.attributes.name,
                required: element.attributes.required,
                user_product: typeProperty.user_product,
                quantity: typeProperty.quantity,
                endpoint: element.attributes.endpoint,
                parameters: element.attributes.parameters,
                alias_product_type: typeProperty.alias_product_type,
              };
            }, []),
            alias_product_type: typeProperty.alias_product_type,
            type: typeProperty.product_type_id.data.attributes.name,
            max_count: Array(typeProperty.quantity)
              .fill({ item: 0, active: false })
              .map((_, indexStatusMaxCount) => {
                return {
                  item: indexStatusMaxCount + 1,
                  active: indexStatusMaxCount === 0 ? true : false,
                  completed: false,
                };
              }),
          };
          typeProperty.properties.data.forEach((property) => {
            const nameProductType =
              typeProperty.product_type_id.data.attributes.name;
            const nameAttribute = property.attributes.name;
            handleValuesProperty(nameProductType, nameAttribute, property.id);
          });
        });
        setPropertiesShortLink(
          createAccountReducer.short_link_properties.properties ||
            checkoutReducer?.short_link_properties?.properties
        );
        setReferenceId(
          createAccountReducer.referenceId ||
            (checkoutReducer.referenceId as string)
        );
        setProperties([...dataMapping]);
        setPropertiesStatus([...propertiesStatus]);
        if (
          Object.keys(checkoutReducer?.short_link_properties?.properties).length
        ) {
          await validateProperties(false, dataMapping);
        }
      } catch (error) {
        console.log('error', error);
      }
    })();
  }, [setPropertiesStatus]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setFilesData = (data: any, control: string) => {
    filesFormData.set(control, data);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mappingDataRequest = (
    data: any,
    dataMapping?: PropertiesResponse[]
  ) => {
    const dataProperties: {
      atributos: IAtribute[];
    }[] = [];
    let dataObject = {};
    (properties.length
      ? properties
      : (dataMapping as PropertiesResponse[])
    ).forEach((typeProperty) => {
      Array(typeProperty.quantity)
        .fill(1)
        .forEach((_, indexQuantity: number) => {
          const atributos = typeProperty.properties.data
            .map((property) => {
              const valor =
                data[
                  `${typeProperty.product_type_id.data.attributes.name}__${property.attributes.name}__${indexQuantity}__${property.id}`
                ];
              if (valor !== undefined) {
                if (propertiesCreated.length) {
                  const propertiesCreatedMap = propertiesCreated.flatMap(
                    (objeto: any) => objeto.properties
                  );
                  const propertyAdded = propertiesCreatedMap.find(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (p: any) => {
                      return (
                        p.valor.toString().toLocaleLowerCase() ==
                          valor.toString().toLocaleLowerCase() &&
                        p.propiedad.id == property.id &&
                        p.producto_usuario == typeProperty.user_product
                      );
                    }
                  );
                  if (propertyAdded) {
                    dataObject = {
                      propiedad_usuario: propertyAdded.propiedad_usuario.id,
                      id: propertyAdded.id,
                    };
                  }
                }

                return {
                  valor,
                  status: true,
                  propiedad: property.id,
                  user_product: typeProperty.user_product,
                  product_type_id: typeProperty.product_type_id.data.id,
                  ...dataObject,
                };
              }
              return null;
            })
            .filter((atributo) => atributo !== null) as IAtribute[];
          if (atributos.length) {
            dataProperties.push({
              atributos,
            });
          }
        });
    });
    return dataProperties;
  };

  const validateProperties = async (
    permissionNavigate: boolean,
    dataMapping?: PropertiesResponse[]
  ) => {
    let valuesForm: FormikValues = {};
    const formPetsValues = formPetsRef.current?.getValues() as FormikValues;
    const formAutoValues = formAutoRef.current?.getValues() as FormikValues;
    const formMotorcycleValues =
      formMotorcycleRef.current?.getValues() as FormikValues;
    const formHomeValues = formHomeRef.current?.getValues() as FormikValues;
    valuesForm = {
      ...formPetsValues,
      ...formAutoValues,
      ...formMotorcycleValues,
      ...formHomeValues,
    };

    setFormValues({ ...valuesForm });
    if (
      (formPetsValues && Object.keys(formPetsValues).length <= 0) ||
      (formAutoValues && Object.keys(formAutoValues).length <= 0) ||
      (formMotorcycleValues && Object.keys(formMotorcycleValues).length <= 0) ||
      (formHomeValues && Object.keys(formHomeValues).length <= 0)
    ) {
      setShowPopUp(true);
    } else {
      if (!propertiesCreated.length) {
        const isUploadedFile =
          formPetsValues && Object.keys(formPetsValues).length > 0;
        const propertiesCreatedResponse = await createProperty(
          valuesForm,
          isUploadedFile,
          dataMapping
        );
        const propertiesCreatedMap = propertiesCreatedResponse.data.flatMap(
          (objeto: any) => objeto.attributes
        );
        setPropertiesCreated(propertiesCreatedMap);
      } else {
        const dataRequest = mappingDataRequest(valuesForm, dataMapping);
        setIsDisabled(true);
        await updateProductProperties(
          authReducer.uid || (checkoutReducer.auth_id as string),
          {
            data: [...dataRequest],
          } as unknown as IEditPropertyRequest
        );
        setIsDisabled(false);
      }
      if (permissionNavigate) {
        navigate(props.link);
      }
    }
  };
  const createProperty = async (
    valuesForm: FormikValues,
    isUploadedFile: boolean | undefined,
    dataMapping?: PropertiesResponse[]
  ) => {
    try {
      const dataRequest = mappingDataRequest(valuesForm, dataMapping);
      setIsDisabled(true);
      if (isUploadedFile) {
        filesFormData.set(
          'info',
          JSON.stringify({
            data: [...dataRequest],
          })
        );
        await await createProductProperties(
          authReducer.uid || (checkoutReducer.auth_id as string),
          filesFormData,
          userImpersonationId
        );
      } else {
        return await createProductProperties(
          createAccountReducer.auth_id ||
            authReducer.uid ||
            (checkoutReducer.auth_id as string),
          {
            data: [...dataRequest],
          },
          userImpersonationId
        );
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsDisabled(false);
    }
  };

  const handleValuesProperty = (
    nameProductType: string,
    nameAttribute: string,
    propertyId: number
  ) => {
    if (
      !Object.keys(checkoutReducer.short_link_properties?.properties).length
    ) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let properties: any = [];
    if (
      [
        TypeProperties.VEHICULOS_WITHOUT_ACCENT,
        TypeProperties.VEHICULOS,
      ].includes(nameProductType as TypeProperties)
    ) {
      properties = [
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...checkoutReducer.short_link_properties?.properties.autos,
      ];
    } else if (nameProductType === TypeProperties.HOGAR) {
      properties = checkoutReducer.short_link_properties?.properties.homes.map(
        (element: { [key: string]: string }) => {
          return {
            ['direccion 1']: element.direccion,
            ['direccion 2']: element.direccion2,
            ['codigo zip']: element.codigoPostal,
            nombre: element.nombre,
            ciudad: element.ciudad,
          };
        }
      );
    } else if (
      [TypeProperties.MOTOCICLETA, TypeProperties.MOTORA].includes(
        nameProductType as TypeProperties
      )
    ) {
      properties = [
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...checkoutReducer.short_link_properties?.properties.motorcycles,
      ];
    }
    if (properties) {
      properties.forEach(
        (
          element: {
            [key: string]: string;
          },
          index: number
        ) => {
          setInitialValuesProperties((prevState) => ({
            ...prevState,
            [`${nameProductType}__${nameAttribute}__${index}__${propertyId}`]:
              element[nameAttribute.toLocaleLowerCase()],
          }));
        }
      );
    }
  };

  useLayoutEffect(() => {
    props.handleStep && props.handleStep(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="create__properties__container">
      <DetailAccordion
        wrapperClassName="create__properties__detail__accordion"
        tilte="Compra realizada"
        type="successful"
      >
        <strong>Orden #{referenceId}</strong>
        <br />
        Nos alegra empezar un nuevo viaje junto a ti. Podrás utilizar los
        servicios de tu plan a partir de{' '}
        <strong>72 horas de registrar tu propiedad.</strong>
        <br />
        <br />
        <span className="info">
          En los próximos minutos estarás recibiendo tu contrato al{' '}
          <strong> {authReducer.email || checkoutReducer.email}</strong>
        </span>
      </DetailAccordion>
      <div className="create__properties__form">
        <h3 className="create__properties__form__title">
          información de propiedades
        </h3>
        <p>
          Hola, {authReducer.name || checkoutReducer.name} 👋
          <br /> Completa la información de las propiedades a cubrir para
          empezar a utilizar el servicio.
        </p>
        {properties.map((typeProperty, indexTypeProperty: number) => (
          <>
            {typeProperty.quantity <= 0 ? (
              <div
                key={`properties__complete__${indexTypeProperty}`}
                className="create__properties__complete"
              >
                Las Propiedades ya fueron creadas
              </div>
            ) : (
              <>
                {typeProperty.alias_product_type === TypeProperties.MASCOTA && (
                  <FormPets
                    properties={typeProperty.properties.data}
                    quantity={typeProperty.quantity}
                    typeProduct={
                      typeProperty.product_type_id.data.attributes.name
                    }
                    ref={formPetsRef}
                    setFilesData={setFilesData}
                  ></FormPets>
                )}
                {typeProperty.alias_product_type === TypeProperties.AUTO && (
                  <FormVehicles
                    handleFormValidityChange={handleFormValidityChange}
                    productType={typeProperty.alias_product_type}
                    branch={branchReducer.branch}
                    properties={typeProperty.properties.data}
                    quantity={typeProperty.quantity}
                    typeProduct={
                      typeProperty.product_type_id.data.attributes.name
                    }
                    ref={formAutoRef}
                    values={initialValuesProperties}
                  ></FormVehicles>
                )}
                {[TypeProperties.MOTOCICLETA, TypeProperties.MOTORA].includes(
                  typeProperty.alias_product_type as TypeProperties
                ) && (
                  <FormVehicles
                    handleFormValidityChange={handleFormValidityChange}
                    productType={
                      typeProperty.alias_product_type as
                        | TypeProperties.MOTORA
                        | TypeProperties.MOTOCICLETA
                        | TypeProperties.VEHICULOS
                        | TypeProperties.AUTO
                    }
                    branch={branchReducer.branch}
                    properties={typeProperty.properties.data}
                    quantity={typeProperty.quantity}
                    typeProduct={
                      typeProperty.product_type_id.data.attributes.name
                    }
                    values={initialValuesProperties}
                    ref={formMotorcycleRef}
                  ></FormVehicles>
                )}
                {typeProperty.alias_product_type === TypeProperties.HOGAR && (
                  <FormHomes
                    branch={branchReducer.branch}
                    properties={typeProperty.properties.data}
                    quantity={typeProperty.quantity}
                    typeProduct={
                      typeProperty.product_type_id.data.attributes.name
                    }
                    values={initialValuesProperties}
                    ref={formHomeRef}
                  ></FormHomes>
                )}
              </>
            )}
          </>
        ))}
        <ItemList>
          Verifica que la información que ingresaste sea la correcta, esta solo
          se podrá modificar al finalizar la compra en la sección Mi Perfil.
        </ItemList>
        <div className="create__properties__warning">
          <Button
            color="primary"
            size="medium"
            template="primary"
            typeStyle="rounded"
            type="button"
            label="Finalizar"
            onClick={() => validateProperties(true)}
            isLoading={isDisabled}
            isDisabled={!isFormValid}
          ></Button>
        </div>
      </div>

      {showPopUp && (
        <PopupExistProcess
          title={'¡Cuidado!'}
          message={
            '<strong>Esta por terminar el proceso con la info incompleta.</strong> Para recibir el servicio de asistencia deberá ir a la App o llamar a servicio al cliente para completarla y quedar activo.'
          }
          labelButton="Continuar"
          labelButtonCancel="Salir"
          type="warning"
          closePopUp={showInfoPopUp}
          exitProcess={exitProcess}
        />
      )}
    </div>
  );
};
