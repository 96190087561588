import ajustes_logo from './ajustes_logo.svg';
import arrowItemMenu from './arrow-item-menu.svg';
import arrowPage from './arrow-page.svg';
import arrowSlider from './arrow-slider.svg';
import arrowCalendar from './arrow_calendar.svg';
import arrowDown from './arrow_down.svg';
import arrowDropdown from './arrow_dropdown.svg';
import iconButtonMore from './atoms/button-more-options/icon-btn.svg';
import iconButton from './atoms/button/icon-button.svg';
import american_express from './atoms/card-logos/american_express.svg';
import cardGeneric from './atoms/card-logos/card_generic.svg';
import diners_club from './atoms/card-logos/diners_club.svg';
import discover from './atoms/card-logos/discover.svg';
import master_card from './atoms/card-logos/master_card.svg';
import visa from './atoms/card-logos/visa.svg';
import arrowAccordion from './atoms/detail-accordion/arrow.svg';
import inputHidePassword from './atoms/input/hide-password.svg';
import inputRequired from './atoms/input/required.svg';
import inputShowPassword from './atoms/input/show-password.svg';
import calendari from './atoms/select/calendari.svg';
import auto from './auto.svg';
import auto_black from './auto_black.svg';
import avatar from './avatar.svg';
import billing from './billing.svg';
import cambioDeLlanta from './cambio_de_llanta.png';
import cardInfo from './card_info.png';
import phoneMerakiApp from './celular-meraki-app.svg';
import checkInformative from './check-informative.svg';
import checkWhite from './check-white.svg';
import check from './check.svg';
import chipError from './chip__error.svg';
import chipCheck from './chip_check.svg';
import chipWarning from './chip_warning.svg';
import close from './close.svg';
import connetLogoWhite from './connet_logo_white.svg';
import createAccountIllustration from './create_account_Illustration.svg';
import dlocal from './dlocal.png';
import edit_profile from './edit_profile.svg';
import email from './email.svg';
import error from './error.svg';
import errorOutline from './error_outline.svg';
import freemuin from './freemuin.svg';
import headerIndicator from './header_indicator.svg';
import history from './history.svg';
import hogarTitle from './hogar.png';
import hogar from './hogar.svg';
import hogar_black from './hogar_black.svg';
import iconArrowRight from './icon_arrow_right.svg';
import icono_plan from './icono_plan.svg';
import image_credit_card from './image-credit-card.svg';
import information from './information.svg';
import listCheck from './list_check.svg';
import logoConnectWhite from './logo-connect-white.svg';
import logoFacebookFooter from './logo-facebook-secondary.svg';
import logoIstagramFooter from './logo-instagram.svg';
import logoConnect from './logo_connect.svg';
import logoConnectOnly from './logo_connect_only.svg';
import logoFacebook from './logo_facebook.svg';
import logoGoogle from './logo_google.svg';
import mascota from './mascota.svg';
import { default as moto, default as motora } from './motora.svg';
import motora_black from './motora_black.svg';
import paymentInfo from './payment_info.svg';
import personaCel from './persona-cel.png';
import persona1 from './persona_fondo1.png';
import persona2 from './persona_fondo2.png';
import pla_item_empty from './pla-item-empty.svg';
import plan_auto from './plan_auto.svg';
import plus from './plus.svg';
import properties from './properties.svg';
import propertyCard from './property__card.svg';
import propertyHome from './property__home.svg';
import logout from './salir.svg';
import section_error_icon from './section-error-icon.svg';
import srConnectBlack from './sr_connect_black.svg';
import stripeBadge from './stripe-badge.jpg';
import stripe from './stripe.png';
import support from './support.jpg';
import surferVan from './surfer_van.png';
import trash from './trash.svg';
import vanSrConnect from './van_sr_connect.svg';
import warning from './warning.svg';
import {
  default as warningDanger,
  default as warningOutline,
} from './warning_danger.svg';
import welcome_1 from './welcome_1.svg';
import welcome_2 from './welcome_2.svg';

export const Images = {
  logoConnect,
  logoConnectOnly,
  logoGoogle,
  logoFacebook,
  check,
  checkInformative,
  iconArrowRight,
  arrowDropdown,
  arrowDown,
  arrowCalendar,
  information,
  trash,
  moto,
  motora,
  motora_black,
  mascota,
  auto,
  hogar,
  hogar_black,
  cardGeneric,
  inputRequired,
  inputHidePassword,
  inputShowPassword,
  arrowPage,
  arrowSlider,
  arrowItemMenu,
  close,
  iconButton,
  arrowAccordion,
  error,
  warning,
  warningDanger,
  chipWarning,
  chipError,
  logoFacebookFooter,
  logoIstagramFooter,
  iconButtonMore,
  calendari,
  properties,
  history,
  billing,
  support,
  createAccountIllustration,
  propertyCard,
  propertyHome,
  welcome_1,
  welcome_2,
  visa,
  master_card,
  american_express,
  diners_club,
  discover,
  ajustes_logo,
  icono_plan,
  plan_auto,
  avatar,
  edit_profile,
  logout,
  email,
  image_credit_card,
  pla_item_empty,
  section_error_icon,
  plus,
  logoConnectWhite,
  chipCheck,
  checkWhite,
  phoneMerakiApp,
  srConnectBlack,
  headerIndicator,
  connetLogoWhite,
  paymentInfo,
  listCheck,
  vanSrConnect,
  personaCel,
  surferVan,
  cambioDeLlanta,
  warningOutline,
  errorOutline,
  stripeBadge,
  stripe,
  dlocal,
  auto_black,
  persona1,
  persona2,
  cardInfo,
  hogarTitle,
  freemuin,
};
