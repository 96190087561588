import { HeaderWithAlert, PaymentMethod } from 'components';
import { UserDetails } from 'components/molecules/User-Details/User-Details';
import { usePaymentsBillingRender } from '../Payments-billing/hooks/Payments-billing-render.hook';
import { usePaymentsAndBilling } from '../Payments-billing/hooks/Payments-billing.hook';
import './Subscriptions.scss';
import { SubscriptionsList } from './components/SubscriptionsList';
import { useSubscriptions } from './hooks/Subscriptions.hook';

export const Subscriptions = () => {
  const { steps, navigate, user } = useSubscriptions();

  const {
    infoPopUp,
    handlePopUp,
    isLoadingCards,
    userCards,
    showPopUp,
    showPopUpEditCardCredit,
    setShowModalEditPaymentMethod,
    showModalEditPaymentMethod,
    goToEditCardCredit,
    isLoadingEdit,
    isLoading,
    amountToRefund,
    deleteItemFromUser,
  } = usePaymentsAndBilling();

  const { renderUserCards } = usePaymentsBillingRender();

  return (
    <>
      <HeaderWithAlert
        steps={steps}
        onClick={() => navigate('/settings')}
      ></HeaderWithAlert>
      <UserDetails name={`${user.name} ${user.lastname}`} email={user.email} />
      <main
        className="subscriptions__container container__generic"
        role="main"
        aria-label="Gestión de suscripciones"
      >
        <section
          className="subscriptions__container__section"
          aria-label="Lista de suscripciones"
        >
          <SubscriptionsList />
        </section>
        <section
          className="subscriptions__payment"
          aria-label="Métodos de pago"
        >
          <PaymentMethod
            isLoadingCards={isLoadingCards}
            userCards={userCards}
            renderUserCards={renderUserCards}
            infoPopUp={infoPopUp}
            showPopUp={showPopUp}
            handlePopUp={handlePopUp}
            showPopUpEditCardCredit={showPopUpEditCardCredit}
            showModalEditPaymentMethod={showModalEditPaymentMethod}
            setShowModalEditPaymentMethod={setShowModalEditPaymentMethod}
            goToEditCardCredit={goToEditCardCredit}
            isLoadingEdit={isLoadingEdit}
            amountToRefund={amountToRefund}
            deleteItemFromUser={deleteItemFromUser}
            isLoading={isLoading}
          />
        </section>
      </main>
    </>
  );
};
