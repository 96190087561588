import { CardBody } from 'components/atoms/Card-body/Card-body';
import { SummaryInformationTotals } from 'components/molecules/Summary-information-totals/Summary-information-totals';
import { AccordionDetail } from 'components/organisms/Accordion-detail/Accordion-detail';
import { FormCoupon } from 'components/organisms/Form-cupon/Form-cupon';
import { ICouponInformation } from 'components/organisms/Form-cupon/models/Form-cupon.interface';
import { ItemBuyProduct } from 'components/organisms/Item-buy-product/Item-buy-product ';
import { ItemPeriodicity } from 'components/organisms/Item-periodicity/Item-periodicity';
import * as Analytics from 'helpers/segment.helper';
import { PeriodicityOptions } from 'models/periodicity.interface';
import { IProductSegment } from 'models/segment.interface';
import { PeriodicityTypesOptions } from 'pages/Checkout/models/Payment-subscription.interface';
import { IProduct } from 'pages/Checkout/pages/Payment-information/models/Payment-information.interface';
import { accordionDetailInformationMapping } from 'pages/Checkout/pages/Purchase-order/mapping/Purchase-order.mapping';
import { setCheckoutInformation } from 'pages/Checkout/store/actions/checkout.types';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { getCountry, getCurrencyForCountry } from 'utils/branch.utils';
import { formatPrice } from 'utils/payments.utils';
import {
  calculatePrices,
  calculateTotal,
  getArrayForPlanIds,
  getPlanIsFreeTrial,
} from 'utils/pricing.utils';
import './Cart-Checkout.scss';

interface ICartCheckoutProps {
  isCuponHidden?: boolean;
  branch: string;
  planItemsQuantity: number[];
  allProducts: IProduct[];
  coupon?: ICouponInformation;
  orderId: string;
  templete: 'outline' | 'default';
  agentId?: string;
  agentName?: string;
  periodicity?: PeriodicityTypesOptions;
  companyName?: string;
  onCartCheckoutChanged?: (planIds: number[]) => void;
  termsAndConditions?: string;
  periodicityIsHidden?: boolean;
  summaryIsHidden?: boolean;
  onlyCount?: boolean;
  title?: string;
  titleAccordion?: string;
  titleTermsAndConditions?: string;
}

export const CartCheckout = forwardRef(function CartCheckout(
  props: ICartCheckoutProps,
  ref
) {
  useImperativeHandle(ref, () => {
    return {
      sendOrderCompleted,
      saveOrderInformation,
      resetCopunInfo,
      getCoupon,
    };
  });

  const cartCheckoutRef = useRef(null);
  const formCouponRef =
    useRef<{
      validateCoupon: (
        planIds: number[],
        coupon: ICouponInformation
      ) => Promise<void>;
    }>(null);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [planWhitFreeTrial, setPlanWhitFreeTrial] = useState(false);
  const [planIds, setPlanIds] = useState<number[]>([]);
  const dispatch = useDispatch();
  const [pricingInfo, setPricingInfo] =
    useState<{
      productsPeriodicityAnual: {
        data: IProduct[];
        prices: {
          subtotal: number;
          discount: number;
          total: number;
          discountPeriodicity: number;
          totalsForPlan: {
            price: number;
          }[];
        };
      };
      productsPeriodicityMensual: {
        data: IProduct[];
        prices: {
          subtotal: number;
          discount: number;
          total: number;
          discountPeriodicity: number;
          totalsForPlan: {
            price: number;
          }[];
        };
      };
    }>();
  const [planItemsQuantity, setPlanItemsQuantity] = useState<number[]>(
    props.planItemsQuantity
  );
  const [totals, setTotals] = useState<{
    subtotal: number;
    discount: number;
    total: number;
    totalsForPlan?: {
      price: number;
      priceByUnit: number;
    }[];
    discountForPeriodicity?: number;
    couponAmount: number;
    priceForRoad: number;
  }>({
    subtotal: 0,
    discount: 0,
    total: 0,
    totalsForPlan: [{ price: 0, priceByUnit: 0 }],
    discountForPeriodicity: 0,
    couponAmount: 0,
    priceForRoad: 0,
  });
  const [otherTotals, setOtherTotals] = useState<{
    subtotal: number;
    discount: number;
    total: number;
    totalsForPlan?: {
      price: number;
      priceByUnit: number;
    }[];
    discountForPeriodicity?: number;
    couponAmount: number;
    priceForRoad: number;
  }>({
    subtotal: 0,
    discount: 0,
    total: 0,
    totalsForPlan: [{ price: 0, priceByUnit: 0 }],
    discountForPeriodicity: 0,
    couponAmount: 0,
    priceForRoad: 0,
  });
  const [coupon, setCoupon] = useState<ICouponInformation | undefined>(
    props.coupon
  );
  const [dataOrderCompletedInformation, setDataOrderCompletedInformation] =
    useState<{
      total: number | undefined;
      discount: number | undefined;
      coupon: ICouponInformation;
      periodicity: PeriodicityTypesOptions;
      currency: string;
      products: IProductSegment[] | undefined;
    }>();
  const [periodicity, setPeriodicity] = useState<PeriodicityTypesOptions>();
  const getPeriodicityTypesOptions = (products: IProduct[]) => {
    const options: PeriodicityTypesOptions[] = [];
    products.forEach((product) => {
      if (
        !options.includes(
          product.attributes.periocidad.data.attributes
            .name as PeriodicityOptions
        )
      ) {
        options.push(
          product.attributes.periocidad.data.attributes
            .name as PeriodicityOptions
        );
      }
    });
    const arraySort = options.sort((a, b) => (a < b ? 1 : -1));
    return arraySort;
  };
  const [periodicityTypesOptions] = useState<PeriodicityTypesOptions[]>(() =>
    getPeriodicityTypesOptions(props.allProducts)
  );
  const getButtonLessDisabledForItem = (index: number): boolean => {
    let isDisabled = false;
    const newPlanItemsQuantity = [...planItemsQuantity];
    if (planItemsQuantity.length === 1 && newPlanItemsQuantity[index] === 1) {
      isDisabled = true;
    } else {
      if (newPlanItemsQuantity[index] === 0) {
        isDisabled = true;
      } else {
        const withOutProps = newPlanItemsQuantity.filter((p) => p === 0);
        if (
          newPlanItemsQuantity[index] === 1 &&
          newPlanItemsQuantity.length - 1 == withOutProps.length
        ) {
          isDisabled = true;
        } else {
          isDisabled = false;
        }
      }
    }
    return isDisabled;
  };
  const calculateAllPrices = (
    item: PeriodicityTypesOptions,
    itemsQuantity: number[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    couponInfo: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any = null
  ) => {
    const dataAllProducts = props.allProducts
      ? [...props.allProducts]
      : [...data];
    const periodicityOption =
      dataAllProducts[dataAllProducts.length - 1].attributes.periocidad.data
        .attributes.name;
    const periodicityInfo =
      item || periodicity || props.periodicity || periodicityOption;
    const pricing = calculatePrices(
      periodicityInfo as PeriodicityOptions,
      itemsQuantity,
      couponInfo,
      dataAllProducts
    );
    if (itemsQuantity.length === 0) {
      itemsQuantity = Array.from(
        periodicityInfo === PeriodicityOptions.ANUAL
          ? [...pricing.productsPeriodicityAnual.data]
          : [...pricing.productsPeriodicityMensual.data],
        (x, index) => (index > 0 ? 0 : 1)
      );
      setPlanItemsQuantity(itemsQuantity);
    }
    setProducts(
      periodicityInfo === PeriodicityOptions.ANUAL
        ? [...pricing.productsPeriodicityAnual.data]
        : [...pricing.productsPeriodicityMensual.data]
    );
    setOtherTotals({
      ...pricing.productsPeriodicityMensual.prices,
      discountForPeriodicity:
        pricing.productsPeriodicityMensual.prices.discountPeriodicity,
      totalsForPlan: pricing.productsPeriodicityMensual.prices.totalsForPlan,
      couponAmount: pricing.productsPeriodicityMensual.prices.couponAmount,
    });
    setTotals({
      ...pricing.productsPeriodicityAnual.prices,
      totalsForPlan: pricing.productsPeriodicityAnual.prices.totalsForPlan,
      discountForPeriodicity:
        pricing.productsPeriodicityAnual.prices.discountPeriodicity,
      couponAmount: pricing.productsPeriodicityAnual.prices.couponAmount,
    });
    setPricingInfo(pricing);
    const orderInformation = saveOrderInformation(periodicityInfo, pricing);
    return { pricing, orderInformation };
  };

  const IsFreeTrial = async (dataOrder: any) => {
    const value = getPlanIsFreeTrial(dataOrder);
    setPlanWhitFreeTrial(value);
  };

  const handlePeriodicity = async (item: PeriodicityTypesOptions) => {
    setPeriodicity(item);
    const values = saveOrderInformation(item);
    const planIDs = values.order.dataOrderCompleted.products?.map((p) => {
      return { quantity: p.quantity, product_id: p.product_id };
    }) as { quantity: number; product_id: number }[];
    const arrayPlanIds = getArrayForPlanIds(planIDs);
    setPlanIds(arrayPlanIds);
    await formCouponRef?.current?.validateCoupon(
      arrayPlanIds,
      coupon as ICouponInformation
    );
    props.onCartCheckoutChanged && props.onCartCheckoutChanged(arrayPlanIds);
  };
  const handlePlanItemsQuantity = (value: number | string, index?: number) => {
    planItemsQuantity[index as number] = Number(value);
    setPlanItemsQuantity([...planItemsQuantity]);
    const dataPricing = calculateTotal(products, planItemsQuantity, coupon);
    const { orderInformation } = calculateAllPrices(
      periodicity as PeriodicityTypesOptions,
      planItemsQuantity,
      coupon
    );
    const data = {
      total: dataPricing.total,
      discount: dataPricing.discount,
      coupon: coupon?.coupon_code as string,
      currency: getCurrencyForCountry(Number(getCountry(props.branch))),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      products: products.map((plan: any, indexPlan: number) => {
        return {
          product_id: plan.id,
          category:
            plan.attributes.productos.data[0].attributes.product_type_id.data
              .attributes.name,
          name: plan.attributes.alias_title,
          alias_name: plan.attributes.alias_name,
          price: plan.attributes.productos.data[0].attributes.price,
          adicionalPrice:
            plan.attributes.productos.data[0].attributes.additional_price,
          quantity: planItemsQuantity[indexPlan],
        };
      }, []),
    };
    Analytics.orderUpdated({
      ...data,
      order_id: props.orderId,
    });
    const planIDs = orderInformation.order.dataOrderCompleted.products?.map(
      (p) => {
        return { quantity: p.quantity, product_id: p.product_id };
      }
    ) as { quantity: number; product_id: number }[];
    const arrayPlanIds = getArrayForPlanIds(planIDs);
    setPlanIds(arrayPlanIds);
    formCouponRef?.current?.validateCoupon(
      arrayPlanIds,
      coupon as ICouponInformation
    );
    props.onCartCheckoutChanged && props.onCartCheckoutChanged(arrayPlanIds);
  };
  const getCoupon = (response: ICouponInformation) => {
    setCoupon(response);
    calculateAllPrices(
      periodicity as PeriodicityTypesOptions,
      planItemsQuantity,
      response
    );
  };
  const resetCopunInfo = () => {
    setCoupon(undefined);
    calculateAllPrices(
      periodicity as PeriodicityTypesOptions,
      planItemsQuantity,
      null
    );
  };
  const setPeriodicityInfo = (
    response: {
      attributes: { periocidad: { data: { attributes: { name: string } } } };
    }[]
  ) => {
    setPeriodicity(
      response[response.length - 1].attributes.periocidad.data.attributes
        .name as PeriodicityTypesOptions
    );
  };

  useEffect(() => {
    const allPeriodicity = props.allProducts.map(
      (product) => product.attributes.periocidad.data.attributes.name
    );
    const uniquePeriodicity = [...new Set(allPeriodicity)];
    const periodicityOption =
      uniquePeriodicity.length > 1
        ? uniquePeriodicity[1]
        : uniquePeriodicity[0];
    const { pricing: productsFiltered, orderInformation } = calculateAllPrices(
      props.periodicity || (periodicityOption as PeriodicityTypesOptions),
      planItemsQuantity,
      coupon ?? props.coupon,
      props.allProducts
    );
    if (props.periodicity) {
      setPeriodicity(props.periodicity);
    } else {
      setPeriodicityInfo(
        productsFiltered.productsPeriodicityMensual.data.length
          ? [...productsFiltered.productsPeriodicityMensual.data]
          : [...productsFiltered.productsPeriodicityAnual.data]
      );
    }
    const planIDs = orderInformation.order.dataOrderCompleted.products?.map(
      (p) => {
        return { quantity: p.quantity, product_id: p.product_id };
      }
    ) as { quantity: number; product_id: number }[];
    const arrayPlanIds = getArrayForPlanIds(planIDs);
    setPlanIds(arrayPlanIds);
    formCouponRef?.current?.validateCoupon(
      arrayPlanIds,
      coupon as ICouponInformation
    );
  }, [props.allProducts]);

  const makeDataOrderCompleted = (
    periodicityValue: PeriodicityTypesOptions,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataPricing: any
  ) => {
    const dataOrderCompleted = {
      total: dataPricing?.prices.total,
      subTotal: dataPricing?.prices.subtotal,
      discount: dataPricing?.prices.discount,
      discountForFreeTrial: dataPricing?.prices.discountForFreeTrial,
      couponAmount: dataPricing?.prices.couponAmount,
      coupon: coupon as ICouponInformation,
      periodicity: periodicityValue as PeriodicityTypesOptions,
      currency: getCurrencyForCountry(Number(getCountry(props.branch))),
      priceForRoad: dataPricing?.prices.priceForRoad,
      totalsForPlan: dataPricing?.prices.totalsForPlan,
      products: [...(dataPricing?.data as IProduct[])]
        .filter((p) => p)
        .map((plan, indexPlan: number) => {
          return {
            product_id: plan.id,
            product_type_id:
              plan.attributes.productos.data[0].attributes.product_type_id.data
                .id,
            category:
              plan.attributes.productos.data[0].attributes.alias_product_type ||
              plan.attributes.productos.data[0].attributes.product_type_id.data
                .attributes.name,
            name: plan.attributes.alias_title,
            url_policies: plan.attributes.url_policies,
            alias_name: plan.attributes.alias_name,
            price: plan.attributes.productos.data[0].attributes.price,
            adicionalPrice:
              plan.attributes.productos.data[0].attributes.additional_price,
            quantity: planItemsQuantity.length
              ? planItemsQuantity[indexPlan]
              : 1,
            trialPeriod: plan.attributes.productos.data[0].attributes.has_trial
              ? plan.attributes.productos.data[0].attributes.trial_period
              : 0,
            maxAdditional:
              plan.attributes.productos.data[0].attributes.max_additional,
            maxCount: plan.attributes.productos.data[0].attributes.max_count,
            profit_title:
              plan.attributes.productos.data[0].attributes.profit_title,
            profit_price:
              plan.attributes.productos.data[0].attributes.profit_price,
          };
        }, []) as IProductSegment[] | undefined,
    };
    setDataOrderCompletedInformation({ ...dataOrderCompleted });
    IsFreeTrial(dataOrderCompleted);
    return dataOrderCompleted;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sendOrderCompleted = (order: any) => {
    Analytics.orderCompleted({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...(order as any),
    });
  };
  const saveOrderInformation = (
    value?: PeriodicityTypesOptions,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pricing?: any
  ) => {
    const periodicityValue = value ? value : periodicity;
    const dataPricing = pricing
      ? periodicityValue === PeriodicityOptions.ANUAL
        ? pricing.productsPeriodicityAnual
        : pricing.productsPeriodicityMensual
      : periodicityValue === PeriodicityOptions.ANUAL
      ? pricingInfo?.productsPeriodicityAnual
      : pricingInfo?.productsPeriodicityMensual;
    const dataOrder = makeDataOrderCompleted(
      periodicityValue as PeriodicityTypesOptions,
      dataPricing
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataRequest: any[] = [];
    [...(dataPricing?.data as IProduct[])].forEach(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (product: any, index: number) => {
        dataRequest[index] = {
          stripe_price_id:
            product.attributes.productos.data[0].attributes.stripe_price_id,
          quantity: planItemsQuantity[index],
          product_id: product.attributes.productos.data[0].id,
          is_additional: false,
          plan_id: product.id,
        };
      }
    );
    const values = {
      products: [],
      agentId: props.agentId,
    };
    values.products = dataRequest
      .reduce((acc, val) => acc.concat(val), [])
      .filter(
        (product: { quantity: number }) => product && product.quantity > 0
      );
    const idForPlan = getArrayForPlanIds(
      dataOrder.products as { quantity: number; product_id: number }[]
    );
    setPlanIds(idForPlan);
    const dataCheckout = {
      order: {
        id: props.orderId,
        dataOrderCompleted: {
          ...dataOrder,
        },
        dataProducts: {
          ...values,
          periodicity: periodicityValue,
          planItemsQuantity,
          plan_id: values.products
            .map((product) => (product as { plan_id: number }).plan_id)
            .join(','),
        },
      },
      resumen: {
        category: (dataOrder.products as [])
          .filter((p: { quantity: number }) => p.quantity > 0)
          .map((p: { category: string }) => p.category, []),
        plans: (dataOrder.products as [])
          .filter((p: { quantity: number }) => p.quantity > 0)
          .map((p: { name: string; url_policies: string }) => ({
            title: p.name,
            url_policies: p.url_policies,
          })),
        subTotal: dataOrder.subTotal,
        price: dataOrder.total,
        discount: dataOrder.discount,
        discountCoupon: dataOrder.couponAmount,
        discountForFreeTrial: dataOrder.discountForFreeTrial,
      },
    };
    dispatch(setCheckoutInformation({ ...dataCheckout }));
    return dataCheckout;
  };
  return (
    <div className="cart__checkout__container" ref={cartCheckoutRef}>
      <CardBody templete={props.templete}>
        {products && (
          <>
            {props.title && (
              <h5 className="cart__checkout__container__title">
                {props.title}
              </h5>
            )}
            {products.map((product, index: number) => (
              <ItemBuyProduct
                index={index}
                showTitle={
                  products[index - 1] &&
                  products[index - 1].attributes.alias_name
                    .toLocaleLowerCase()
                    .indexOf('carretera') > 0 &&
                  product.attributes.alias_name
                    .toLocaleLowerCase()
                    .indexOf('carretera') > 0
                    ? false
                    : true
                }
                periodicity={periodicity as PeriodicityTypesOptions}
                branch={props.branch}
                maxItems={
                  product.attributes.productos.data[0].attributes.max_count
                }
                key={`item_product_${index}`}
                count={planItemsQuantity[index]}
                products={product.attributes.productos.data}
                title={product.attributes.alias_title}
                aliasName={
                  props.onlyCount ? 'propiedad' : product.attributes.alias_name
                }
                type={product.attributes.alias_product_type}
                priceForUnit={
                  !props.onlyCount
                    ? periodicity === PeriodicityOptions.ANUAL
                      ? totals.totalsForPlan && totals.totalsForPlan[index]
                        ? totals.totalsForPlan[index].priceByUnit
                        : 0
                      : otherTotals.totalsForPlan &&
                        otherTotals.totalsForPlan[index]
                      ? otherTotals.totalsForPlan[index].priceByUnit
                      : 0
                    : undefined
                }
                price={
                  !props.onlyCount
                    ? periodicity === PeriodicityOptions.ANUAL
                      ? totals.totalsForPlan && totals.totalsForPlan[index]
                        ? totals.totalsForPlan[index].price
                        : 0
                      : otherTotals.totalsForPlan &&
                        otherTotals.totalsForPlan[index]
                      ? otherTotals.totalsForPlan[index].price
                      : 0
                    : undefined
                }
                priceForRoad={
                  periodicity === PeriodicityOptions.ANUAL
                    ? totals.priceForRoad
                      ? totals.priceForRoad
                      : 0
                    : otherTotals.priceForRoad
                    ? otherTotals.priceForRoad
                    : 0
                }
                onClick={(value) => handlePlanItemsQuantity(value, index)}
                isDisabledButtonLess={
                  getButtonLessDisabledForItem(index) || planWhitFreeTrial
                }
                isDisabledButtonPlus={
                  planItemsQuantity[index] >=
                  product.attributes.productos.data[0].attributes.max_count
                }
                id={product.id}
              ></ItemBuyProduct>
            ))}
            <AccordionDetail
              title={props.titleAccordion}
              titleTermsAndConditions={props.titleTermsAndConditions}
              data={accordionDetailInformationMapping(products)}
              planItemsQuantity={planItemsQuantity}
            ></AccordionDetail>
          </>
        )}
      </CardBody>
      {!props.periodicityIsHidden && (
        <ItemPeriodicity
          discount={totals.discountForPeriodicity as number}
          otherDiscount={otherTotals.discountForPeriodicity as number}
          periodicity={periodicity as PeriodicityTypesOptions}
          options={periodicityTypesOptions}
          branch={props.branch}
          subTotal={totals.subtotal}
          total={totals.total}
          subOtherTotal={otherTotals.subtotal}
          otherTotal={otherTotals.total}
          onClick={handlePeriodicity}
          templete="default"
        ></ItemPeriodicity>
      )}
      {!props.isCuponHidden && (
        <FormCoupon
          ref={formCouponRef}
          orderId={props.orderId}
          coupon={coupon}
          value={coupon?.coupon_code}
          onResetCuponInfo={resetCopunInfo}
          onGetCoupon={getCoupon}
          periodicity={periodicity as PeriodicityTypesOptions}
          branch={props.branch}
          marginSmall={planWhitFreeTrial}
          planIds={planIds}
          templete={'default'}
        ></FormCoupon>
      )}
      {!props.summaryIsHidden && (
        <SummaryInformationTotals
          templete="default"
          values={
            periodicity === PeriodicityOptions.ANUAL ? totals : otherTotals
          }
          branch={props.branch}
          decorationHidden={false}
          isTrialPeriod={planWhitFreeTrial ? true : false}
          termsAndConditions={
            coupon?.terms_and_conditions ? coupon?.terms_and_conditions : ''
          }
          couponName={coupon?.name as string}
          periodicity={periodicity as PeriodicityTypesOptions}
          products={dataOrderCompletedInformation?.products}
        ></SummaryInformationTotals>
      )}
      {products && planWhitFreeTrial ? (
        <p className="cart__checkout__message__trial">
          {products.map((product, index: number) =>
            product.attributes.productos.data[0].attributes.trial_period ? (
              <span key={index}>
                <br />
                {`Tienes ${
                  product.attributes.productos.data[0].attributes.trial_period
                } días para disfrutar totalmente
                  gratis del ${(
                    product.attributes.alias_title || ''
                  ).toLocaleLowerCase()}.
                  Finalizado el tiempo se realizará el cobro de $${formatPrice(
                    periodicity === PeriodicityOptions.ANUAL
                      ? totals.totalsForPlan && totals.totalsForPlan[index]
                        ? totals.totalsForPlan[index].price
                        : 0
                      : otherTotals.totalsForPlan &&
                        otherTotals.totalsForPlan[index]
                      ? otherTotals.totalsForPlan[index].price
                      : 0,
                    props.branch
                  )}. Beneficio válido
                  solo para miembros de ${props.companyName} aplican T&C.`}
              </span>
            ) : (
              ''
            )
          )}
        </p>
      ) : (
        ''
      )}
    </div>
  );
});
