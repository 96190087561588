import { RequestDataEditPlan } from 'pages/Edit-plan/Edit-plan-information/models/Edit-plan-information.interface';
import cms from './cms';

/**
 * Function to get all plans
 * @returns
 */
export async function getPlans() {
  const response = await cms.get('subscriptions');
  return response.data.data;
}
/**
 * Function to get all plans
 * @returns
 */
export async function getCountryForPlan(planId: number) {
  const response = await cms.get(`subscriptions/country/${planId}`);
  return response.data;
}

/**
 * Function to get a plan by id

 * @param id string
 * @returns object
 * @fix post request
 */
export async function getPlanById(id: string, branch: string) {
  const response = await cms.post(`subscriptions/list/${id}/${branch}`);
  return response.data.data;
}
/**
 * Function to get a plan by company id

 * @param id string
 * @returns object
 * @fix post request
 */
export async function getPlanByCompanyId(id: string) {
  const response = await cms.get(`company/plans/${id}`);
  return response.data;
}

/**
 * Function to get a plan by id
 * @param id string
 * @param countryId string
 * @returns object
 */
export async function getSubscriptionsById(
  planId: string,
  countryId: string | number
) {
  const response = await cms.get(`subscriptions/${planId}/${countryId}`);
  return response.data.data;
}

/**
 * Get all plans by user id
 * @param uuid string
 * @returns object
 */
export async function getUserPlans(
  uuid: string,
  subscriptionId: string | undefined = undefined
) {
  const response = await cms.get(
    `user-subscriptions/user/${uuid}/${subscriptionId ? subscriptionId : ''}`
  );
  return response.data.data;
}

export async function getUserAllPlans(
  uuid: string,
  agentId: number | null = null
) {
  const response = await cms.get(
    `user-subscriptions/user-plans/${uuid}${agentId ? '/' + agentId : ''}`
  );
  return response.data.data;
}

/**
 * Get all plans by user id
 * @param uuid string
 * @returns object
 */
export async function getPropertyForPlan(uuid: string, plandId: string) {
  const response = await cms.get(
    `user-subscriptions/subscription/${plandId}/${uuid}`
  );
  return response.data.data;
}
//@TODO MODIFICAR
export async function getProductsByPlanId(planId: number) {
  const response = await cms.get(`user-products/plan/${planId}`);
  return response.data.data;
}

export async function removePlanFromUser(
  uuid: string,
  planId: number | string,
  isRefund: boolean,
  userImpersonationId: number | null
) {
  const response = await cms.delete(
    `user-subscriptions/${planId}/${uuid}${isRefund ? '/true' : '/false'}${
      userImpersonationId ? '/' + userImpersonationId : ''
    }`
  );
  return response.data.data;
}

export async function tryToCancelSubscription(
  uuid: string,
  planId: number | string,
  norefund = false
) {
  const response = await cms.get(
    `user-subscriptions/delete/${planId}/${uuid}${norefund ? '/true' : ''}`
  );
  return response.data;
}

/**
 * Get all plans by user id
 * @param uuid string
 * @param plandId string
 * @returns object
 */
export async function getInfoToUpdateSubscription(
  uuid: string,
  plandId: string,
  productId: number,
  quantity: number
) {
  const response = await cms.post(
    `user-subscriptions/try-to-update/${plandId}/${uuid}`,
    {
      productId,
      quantity,
    }
  );
  return response.data;
}

export async function tryToUpdateSubscription(
  plandId: string,
  uuid: string,
  data: RequestDataEditPlan
): Promise<void> {
  await cms.put(`user-subscriptions/subscription/${plandId}/${uuid}`, data);
}

/**
 * Get all plans by user id
 * @param uuid string
 * @param plandId string
 * @returns object
 */
export async function tryToUpdateSubscriptionCustomAmount(
  plandId: string,
  uuid: string,
  data: RequestDataEditPlan
) {
  const response = await cms.put(
    `/user-subscriptions/custom-payment-subscription/${plandId}/${uuid}`,
    data
  );
  return response.data;
}
/**
 * Get all plans by user id
 * @param uuid string
 * @param plandId string
 * @returns object
 */
export async function addAddon(data: {
  uid: string;
  planId: number;
  country_id: string;
  productId: string;
  subscriptionId: number;
  process: boolean;
  couponCode: string | null;
  userImpersonationId: number | null;
}) {
  const response = await cms.post(`user-subscriptions/add-addon`, {
    ...data,
  });
  return response.data;
}
