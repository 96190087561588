import { ButtonMoreOptions, Card, Chips, ItemStatus } from 'components';
import { ItemPaymentProps } from 'models/card.interface';
import * as paymentsUtils from 'utils/payments.utils';
import './Item-payment.scss';

export const ItemPayment = (props: ItemPaymentProps) => {
  return (
    <Card
      id={`item__card__${props.id}`}
      WrapperClassName={props.WrapperClassName}
    >
      <div className="item__payment__container">
        <div className="item__payment__actions">
          <img
            className="item__payment__logo"
            src={paymentsUtils.getIconCard(props.brand as string)}
            alt={props.brand}
          />
          <ButtonMoreOptions
            testid={'testid'}
            id={props.id}
            onClickEdit={props.onClickEdit}
            onClickDelete={props.onClickDelete}
            label="Método de pago"
          />
        </div>
        <span className="item__payment__title">Terminada en {props.last4}</span>
        <div className="item__payment__chips">
          <Chips label={`Expira ${props.exp_month}/${props.exp_year}`} />
        </div>
        {props.is_principal && (
          <ItemStatus
            hidenIcon={true}
            message={'Principal'}
            status={props.status}
          ></ItemStatus>
        )}
      </div>
    </Card>
  );
};
