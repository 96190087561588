import cms from './cms';

/**
 * Function to get all products
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function addUserPayment(paymentData: any) {
  const response = await cms.post('payments', paymentData, {
    headers: { 'Content-Type': 'application/json' },
  });
  return parseInt(response.data.data.id);
}

/**
 * Function to get all properties by user id
 * @param uuid uuid
 * @returns Object
 */
export async function getUserPayments(uuid: string) {
  const response = await cms.get(`payments/user/${uuid}`);
  return response.data.data;
}

export async function getNextPayment(uuid: string) {
  const response = await cms.get(`user-subscriptions/nextsubscription/${uuid}`);
  return response.data;
}

export async function renewalSubscriptionService(
  idSubscription: string,
  userImpersonationId: number | null
) {
  const response = await cms.get(
    `user-subscriptions/renewal-subscriptions/${idSubscription}${
      userImpersonationId ? '/' + userImpersonationId : ''
    }`
  );
  return response.data.data;
}
