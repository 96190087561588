import { IUserSubscription } from 'pages/Edit-Property/models/Edit-Property.interface';

const isDateInRange = (date: string, start: string, end: string): boolean => {
  const paymentDate = new Date(date);
  paymentDate.setHours(0, 0, 0, 0);

  const startDate = new Date(start);
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date(end);
  endDate.setHours(0, 0, 0, 0);

  return paymentDate >= startDate && paymentDate <= endDate;
};

const shouldShowPlan = (plan: IUserSubscription): boolean => {
  const productUser =
    plan.attributes.plan_id.data.attributes.user_products?.[0] ?? null;

  if (!productUser) {
    return true;
  }

  const { date_init, date_expire } = productUser;

  const hasCouponWithPayment = plan.attributes.payments.data.some((payment) => {
    const { coupon_code, date } = payment.attributes;
    if (coupon_code && coupon_code !== '') {
      return isDateInRange(date, date_init, date_expire);
    }
    return false;
  });

  return hasCouponWithPayment;
};

/**
 * Función provisional para verificar pagos con cupón en un plan.
 *
 * Actualmente se utiliza para controlar la visibilidad de los planes con pagos
 * válidos con cupones, pero la solución definitiva debería manejarse a través
 * de la funcionalidad de edición de planes.
 */

const checkCouponPayments = (plan: IUserSubscription): boolean => {
  return shouldShowPlan(plan);
};

export { checkCouponPayments };
