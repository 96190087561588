import { ButtonMoreOptions } from 'components/atoms/Button-more-options/Button-more-options';
import { Card } from 'components/atoms/Card/Card';
import { Chips } from 'components/atoms/Chips/Chips';
import { ItemStatus } from 'components/atoms/Item-status/Item-status';
import { IAppReducer } from 'models/reducer.interface';
import { useSelector } from 'react-redux';
import { formatPrice } from 'utils/payments.utils';
import './History-card.scss';

export interface IPropsHistoryCard {
  status:
    | 'successful'
    | 'pending'
    | 'error'
    | 'Activo'
    | 'Cancelado'
    | 'Finalizado';
  message: string;
  data: {
    name: string;
    number: string;
    description: string;
    total: number;
    date: string;
    typeService: string;
    couponName: string;
    couponAmount: string;
    planName: string;
    hasDiscount: boolean;
  };
  agentId?: string | number;
}

export const HistoryCard = (props: IPropsHistoryCard) => {
  const { branch } = useSelector((state: IAppReducer) => state.branchReducer);
  return (
    <div className="history__card__container">
      <Card>
        <div className="history__card__content">
          <div className="button__more__options__container">
            <ButtonMoreOptions testid={'test'} id={'test'} label={'Test'} />
          </div>
          <div className="history__card__title">
            <span className="title--bold row">
              <span>{props.data.name}</span>
              <span>${formatPrice(props.data.total, branch)}</span>
            </span>
          </div>
          <div className="history__card__detail">
            <span className="history__card__detail__address">
              # {props.data.number}
            </span>
            <span>{props.data.date}</span>
            <span>{props.data.planName}</span>
            {props.agentId && props.data.couponName && (
              <span>
                Dscto por cupón {props.data.couponName} ($
                {-props.data.couponAmount})
              </span>
            )}
            {props.agentId && props.data.hasDiscount && (
              <span>Incluye FreeTrial</span>
            )}
          </div>
          <div className="history__card__service">
            <Chips label={props.data.typeService} />
            <ItemStatus
              status={props.status}
              message={props.message}
            ></ItemStatus>
          </div>
        </div>
      </Card>
    </div>
  );
};
